import React from 'react'
import trainer1 from "../Assets/Trainer1.png";
import trainer2 from "../Assets/Trainer2.png";
import personalTrainerFont from "../Assets/PersonalTrainerFont.png";
import personalTrainerMobile from "../Assets/personalTrainerMobile.png";

const Trainers = () => {
    return (
        <div className="trainers">
            <div className="trainerDetail">
                <div className="left">
                    <div className="imgbox">
                        <img loading="lazy" className="trainer" src={trainer2} alt="" />
                    </div>
                </div>
                <div className="right">
                    <h2 className="title">
                        FLINGS OWUSU-AGYAPONG
                    </h2>
                    <div className="desc">
                        <p>
                            Flings held the unique honor of being a flag bearer for her native country of Ghana at the 2016 Olympics. She currently holds multiple national records. After moving to Toronto at the age of 9, Flings became one of the fastest Canadian high schoolers and went on to compete at Syracuse University, where she set school records for 55, 60, and 100 meter dashes. She is now able to leverage her high level athletic experience to the advantage of our Forward Fitness community through her engaging, encouraging, and upbeat training style!
                        </p>
                    </div>

                    <a href="https://youtu.be/ZkF7B4ZIM_o" target="_blank" rel="noreferrer" className="btn learnmore">
                        Learn More <ion-icon name="arrow-forward"></ion-icon>
                    </a>
                </div>
                <div className="personalTrainerFont">
                    <img loading="lazy" src={personalTrainerFont} alt="" />
                </div>
                <div className="personalTrainerMobile">
                    <img loading="lazy" src={personalTrainerMobile} alt="" />
                </div>
            </div>
            <div className="trainerDetail">
                <div className="left">
                    <div className="imgbox">
                        <img loading="lazy" className="trainer" src={trainer1} alt="" />
                    </div>
                </div>
                <div className="right">
                    <h2 className="title">
                        LEXI ARUCK
                    </h2>
                    <div className="desc">
                        <p>
                            Lexi has a background in both the science and health worlds and is able to incorporate that into her work with the Forward Fitness community! A graduate of Syracuse University, she is currently working towards her Masters in Applied Clinical Nutrition and a Doctorate in Chiropractic, giving her a deep understanding of the human body. When you train with Lexi you not only get great workouts and support, you get an education to go along with your gains as well!
                        </p>
                    </div>

                    <a href='https://youtu.be/IEq9hNn41QU' target="_blank" rel="noreferrer" className="btn learnmore">
                        Learn More <ion-icon name="arrow-forward"></ion-icon>
                    </a>
                </div>
                <div className="personalTrainerFont">
                    <img loading="lazy" src={personalTrainerFont} alt="" />
                </div>
                <div className="personalTrainerMobile">
                    <img loading="lazy" src={personalTrainerMobile} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Trainers