import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { planContext } from '../App';
import logoImg from "../Assets/logo.png";
import { logoutApi } from '../Config/Api';


const AdminSide = ({ setAdminLogged }) => {
    const [activeMenu, setActiveMenu] = useState("sales");
    const [activeSubMenu, setActiveSubMenu] = useState("banner");
    const location = useLocation();

    const { allCohorts } = useContext(planContext);

    useEffect(() => {
        setActiveMenu(location.pathname.split('/')[2]);
        setActiveSubMenu(location.pathname.split('/')[3]);
    }, [location]);

    const handleLogout = async () => {
        try {
            const res = await axios.get(logoutApi, { withCredentials: true });
            if (res.status) {
                setAdminLogged(false);
                window.location.reload();
            }
            // }
        } catch (e) {
            // console.log(e);
            alert("login Failed");
        }
    }

    return (
        <div className="adminSide">
            <Link to="/" className="logo">
                <img src={logoImg} alt="" />
            </Link>

            <div className="menus">
                <div className="topMenus">
                    <div className="subMenu">
                        <Link to="/admin/sales/banner" className={activeMenu === "sales" ? "link menuActive" : "link"}>
                            <ion-icon name="person-outline"></ion-icon>
                            Sales
                        </Link>
                        <Link to="/admin/sales/banner" className={activeSubMenu === 'banner' ? 'active subLink' : 'subLink'}>
                            Banner
                        </Link>
                        <Link to="/admin/sales/history" className={activeSubMenu === 'history' ? 'active subLink' : 'subLink'}>
                            History
                        </Link>
                    </div>
                    <div className="subMenu">
                        <Link to={`/admin/cohort`} className={activeMenu === "cohort" ? "link menuActive" : "link"} >
                            <ion-icon name="document-text-outline"></ion-icon>
                            Cohort
                        </Link>
                        <Link to="/admin/cohort/createcohort" className={activeSubMenu === "createcohort" ? 'active subLink' : 'subLink'}>
                            Create Cohort
                        </Link>


                        {
                            allCohorts.length > 0 &&
                            allCohorts?.map((plan, index) => (
                                <Link key={index} to={`/admin/cohort/${plan._id}`} className={activeSubMenu === plan._id ? 'active subLink' : 'subLink'}>
                                    {plan.title}
                                </Link>
                            ))
                        }

                    </div>
                    <div className="subMenu">
                        <Link to={`/admin/customers`} className={activeMenu === "customers" ? "link menuActive" : "link"} >
                            <ion-icon name="people-outline"></ion-icon>
                            Customers
                        </Link>
                    </div>
                    <div className="subMenu">
                        <Link to={`/admin/transactions`} className={activeMenu === "transactions" ? "link menuActive" : "link"} >
                            <ion-icon name="card-outline"></ion-icon>
                            Transactions
                        </Link>
                    </div>
                    <div className="subMenu">
                        <Link to={`/admin/at-a-glance`} className={activeMenu === "at-a-glance" ? "link menuActive" : "link"} >
                            <ion-icon name="information-circle-outline"></ion-icon>
                            At A Glance
                        </Link>
                    </div>
                </div>


                <div className="bottomMenus">
                    <div className="subMenu">
                        <Link to="/admin/settings" className={activeMenu === "settings" ? "link menuActive" : "link"} onClick={() => {
                            setActiveMenu("settings")
                            setActiveSubMenu("")
                        }
                        }>
                            <ion-icon name="settings-outline"></ion-icon>
                            Settings
                        </Link>
                    </div>
                    <div className="subMenu">
                        <div onClick={handleLogout} className="link">
                            <ion-icon name="nuclear-outline"></ion-icon>
                            Logout
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminSide
