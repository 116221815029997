import React, { useState } from 'react'
import AdminSide from './AdminSide'
import AdminTop from './AdminTop'
import { createCohortApi } from '../Config/Api';
import axios from 'axios';
import Loader from './Loader';


const AdminCreateCohort = ({ adminDetails, setAdminLogged }) => {
    const [curPlan, setCurPlan] = useState({ title: "", description: "", totalprice: "", numberofweeks: "", cohortnumber: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [resMsg, setResMsg] = useState("");

    const validateData = () => {
        if (!curPlan.title || !curPlan.description || !curPlan.totalprice || !curPlan.numberofweeks || !curPlan.cohortnumber) {
            setIsError(true);
            setResMsg("Please fill out all the Fields ");
            return false;
        } else if (curPlan.title.length < 5) {
            setIsError(true);
            setResMsg("Title is very small");
            return false;
        } else if (curPlan.description.length < 20) {
            setIsError(true);
            setResMsg("Description is very small");
            return false;
        } else if (curPlan.totalprice < 0) {
            setIsError(true);
            setResMsg("Price must be positive");
            return false;
        } else if (curPlan.numberofweeks <= 0) {
            setIsError(true);
            setResMsg("Number of weeks should be greater than 0");
            return false;
        }
        setIsError(false);
        setResMsg("");
        return true;
    }

    const createCohort = async (e) => {
        e.preventDefault();
        if (validateData()) {
            try {
                setIsLoading(true);
                const res = await axios.post(createCohortApi, { ...curPlan }, { withCredentials: true });
                if (res.status === 201) {
                    setIsError(false);
                    setIsLoading(false);
                    window.location.reload();
                }
            } catch (e) {
                setIsError(true);
                setIsLoading(false);
                if (e.response.status === 400) {
                    setResMsg(e.response.data);
                } else if (e.response.status === 500) {
                    setResMsg(e.response.data);
                } else {
                    setResMsg("Sorry, Something went wrong - Please try after some time.");
                }
            }
        }
    }

    return (
        <div>
            <AdminTop adminDetails={adminDetails} />
            <div className="adminMainContent">
                <AdminSide setAdminLogged={setAdminLogged} />
                {
                    isLoading ?
                        <Loader adminLoader={true} /> :
                        <div className="adminCreateCohort right">
                            <div className="topCohortBar">
                                <h2 className="title">
                                    Cohort &gt; Create Cohort
                                </h2>
                            </div>
                            <form>
                                <div className="formField">
                                    <label className="fieldTitle" htmlFor="programName">
                                        Program Name
                                    </label>
                                    <input value={curPlan.title} onChange={(e) => setCurPlan({ ...curPlan, title: e.target.value })} id="programName" type="text" placeholder="Enter Program Name" />
                                </div>
                                <div className="formField">
                                    <label className="fieldTitle" htmlFor="username">
                                        Program Description (Add $ sign to seperate the paragraph)
                                    </label>
                                    <textarea value={curPlan.description} onChange={(e) => setCurPlan({ ...curPlan, description: e.target.value })} id="username" type="text" placeholder="Enter Program Description" />
                                </div>
                                <div className="fields">
                                    <div className="formField">
                                        <label className="fieldTitle" htmlFor="username">
                                            Program Price (In USD)
                                        </label>
                                        <input onChange={(e) => setCurPlan({ ...curPlan, totalprice: e.target.value })} value={curPlan?.totalprice} id="programName" type="number" placeholder="Price" />
                                    </div>
                                    <div className="formField">
                                        <label className="fieldTitle" htmlFor="username">
                                            Number of Weeks
                                        </label>
                                        <input onChange={(e) => setCurPlan({ ...curPlan, numberofweeks: e.target.value })} value={curPlan?.numberofweeks} id="programName" type="number" placeholder="Number of Weeks" />
                                    </div>
                                    <div className="formField">
                                        <label className="fieldTitle" htmlFor="cohortNumber">
                                            Cohort Number
                                        </label>
                                        <input onChange={(e) => setCurPlan({ ...curPlan, cohortnumber: e.target.value })} value={curPlan?.cohortnumber} id="cohortNumber" type="number" placeholder="Enter Cohort Number" />
                                    </div>
                                </div>
                                <p className={isError ? "resMsg error" : "resMsg"}>
                                    {resMsg}
                                </p>
                                <button onClick={createCohort}>
                                    Create
                                </button>
                            </form>
                        </div>
                }
            </div>

        </div>
    )
}

export default AdminCreateCohort
