import React, { useEffect, useRef, useState } from 'react'
import demo1 from "../Assets/testimonial1.png"
import demo2 from "../Assets/testimonial2.png"
import demo3 from "../Assets/testimonial3.png"
import demo4 from "../Assets/testimonial4.png"
import demo5 from "../Assets/testimonial5.png"
import demo6 from "../Assets/testimonial6.png"
import demo7 from "../Assets/testimonial7.png"
import testimonialFont from "../Assets/Testimonial.png";
import { Link } from "react-router-dom";

const Testimonials = () => {
    const [slideIndex, setSlideIndex] = useState(0);
    const timeoutRef = useRef(null);
    const delay = 10000;

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () =>
                setSlideIndex((prevIndex) =>
                    prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
                ),
            delay
        );

        return () => {
            resetTimeout();
        };
    }, [slideIndex]);

    const reviews = [
        {
            username: "Jabari Butler",
            image: demo1,
            desc: "It has been a ride, a great one at that though. Although it did push me mentally and physically, I’m able to reap the benefits to get to that finish line. I’m seeing drastic improvements in my obliques, my barbell squats, and my deadlifts. My ab cuts are also more visible now in this tight 5-week time which is amazing. All of this is because I’m able to engage my core a lot more and hold those higher weights with a stronger core — all attributed to this class. I normally don’t do core work outs, I just do upper or lower body but having this in place has been amazing for me so if there’s anyone out there that’s looking to build that stronger foundation and have that translate to maybe a lifting stronger lifts in the weight room or having your day feel a lot more stronger and upright, this is the program for you. If you’re trying get that summer body ready and have have those abs popping out on the beach, this is also for you. I encourage you all to take that journey, take that ride and you’ll be happy with the results."
        },
        {
            username: "Alyssa Rueb",
            image: demo2,
            desc: "Not only do I love being able to get a great workout in 30-minutes from home, but I also love the community and sense of family that FF provides. Everyone cares about one another and is so supportive as we work together towards our goals. There was a lot of change in my life including living alone as my now husband had to move for work. FF was a much needed light in my life. Being able to workout everyday with my friends, my new family was incredible. "
        },
        {
            username: "Jake Szabo",
            image: demo3,
            desc: "My time is super limited with a job, a wife, and 2 babies. Being able to have 30-minutes carved out on my calendar to get a great workout has allowed me to be consistent. The accountability that FF provides has been huge for me and my health."
        },
        {
            username: "Yassah Johnson",
            image: demo4,
            desc: "This is probably going to be really personal but I wanted to say thank you. When I started Forward Fitness I was really depressed, at the time. I’d just gotten through an unexpected break up and I had to work through that. You, Forward Fitness and everyone honestly played a huge part in my healing journey. But mentally pushing myself, that was something you helped me with. I was so intimidated going in because I didn’t believe I could do a lot of the work asked of me but I did it. So thank you Amadou. I’ve always wanted to say it but never knew how or when. But just know you made a huge impact. "
        },
        {
            username: "Callan Goldman",
            image: demo5,
            desc: "I truly feel the best in my body I have in yearsssssss. Forward Fitness is nothing short of a lifestyle game-changer and I owe Amadou and the Forward Fitness family my endless thanks for helping me change and grow myself, both physically and mentally. I was both nervous and excited to get started, not sure what to expect of the program and of my body after YEARS of not working out. If you want to unlock the benefits of a structured routine, accountability, and movement in all aspects of your life, FF is a must. I have never felt as confident and capable in my body as I do now, and that is solely because of Forward Fitness. All in all, Forward Fitness not only helped me get my life back on track but set me up for success in the future. I cannot sing their praises loud enough. THANK YOU!"
        },
        {
            username: "Nate Mercer",
            image: demo6,
            desc: "I just finished the 5-week accelerated course and I gave it a 4.5 out of 5 and the only reason I didn’t give a 5/5 is honestly because I wish it was longer because it was that much of a good course. I have always been someone who is active but for some reason doing core has been extremely difficult for me, I couldn’t really motivate myself to do it on my own so having Amadou to keep me accountable for 5-weeks was extremely helpful and honestly the benefits of this course kinda bled over into other areas of my life too. When you commit yourself to do something difficult, it inspires you to do the same so not only my fitness improved but my productivity at work, mental health and it was kinda like a domino effect across the board in my life. I’m extremely thankful to Amadou and I’d recommend this course to everyone and I’m going to do another course (Core V2). The hardest part was showing up but once I started it was akin to therapy for me. I always felt better after doing the class."
        },
        {
            username: "Zack Goldman",
            image: demo7,
            desc: "The program that Amadou has put together is incredibly impressive. He is an engaging trainer who helped me make some serious gains in core strength and stability. The workouts were challenging and fun. Amadou strikes the perfect tone and pushed me to achieve more than I thought I could in five weeks."
        }
    ]

    return (
        <div className="testimonials">
            <h2 className="title">
                What Our Customers are Saying
            </h2>
            <div className="backgroundFont">
                <img loading="lazy" src={testimonialFont} alt="" />
            </div>
            <div className="container">
                <div className="slider">
                    <div className={`allSlides active${slideIndex}`}>
                        {
                            reviews.map((review, index) => {
                                return (
                                    <div key={index} className="slide">
                                        <div className="left">
                                            <img loading="lazy" src={review.image} alt="" />
                                        </div>
                                        <div className="right">
                                            <p className="desc">
                                                "{review.desc}"
                                            </p>
                                            <p className="username">
                                                {review.username}
                                            </p>
                                            <Link className="viewClass" to="/services">
                                                View Classes <ion-icon name="arrow-forward"></ion-icon>
                                            </Link>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="slideController">
                    {
                        reviews.map((review, index) => {
                            return (
                                <div key={index} onClick={() => {
                                    setSlideIndex(index)
                                }} className={(slideIndex === index) ? "dot active" : "dot"}>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        </div>
    )
}

export default Testimonials