import React, { useEffect, useState } from 'react'
import Loader from './Loader';
import AdminSide from './AdminSide'
import AdminTop from './AdminTop'
import axios from 'axios';
import { getFFDetailApi, updateFFDetailApi } from '../Config/Api';

const AdminOurDetails = ({ adminDetails, setAdminLogged }) => {
    const [ourDetails, setOurDetails] = useState({ participants: "", exercisehours: "", classestaught: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [resMsg, setResMsg] = useState("");

    const [detailId, setDetailId] = useState("");

    useEffect(() => {
        const getOurDetails = async () => {
            try {
                setIsLoading(true);
                const res = await axios.get(getFFDetailApi, { withCredentials: true });
                if (res.status === 200) {
                    setOurDetails({
                        participants: res.data.participants,
                        exercisehours: res.data.exercisehours,
                        classestaught: res.data.classestaught
                    });
                    setDetailId(res.data._id);
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                console.log(e);
            }
        }
        getOurDetails();
    }, []);

    const updateOurDetails = async (e) => {
        e.preventDefault();
        if (!ourDetails.participants || !ourDetails.exercisehours || !ourDetails.classestaught) {
            setIsError(true);
            setResMsg("Please fill out all the Fields ");
            return false;
        } else {
            try {
                setIsLoading(true);
                const res = await axios.post(updateFFDetailApi(detailId), ourDetails, { withCredentials: true });
                if (res.status === 200) {
                    setIsLoading(false);
                    setIsError(false);
                    setResMsg(res.data);
                }
            } catch (e) {
                setIsError(true);
                setIsLoading(false);
                if (e.response.status === 400) {
                    setResMsg(e.response.data);
                } else if (e.response.status === 500) {
                    setResMsg(e.response.data);
                } else {
                    setResMsg("Sorry, Something went wrong - Please try after some time.");
                }
                console.log(e);
            }
        }
    }

    return (
        <div>
            <AdminTop adminDetails={adminDetails} />
            <div className="adminMainContent">
                <AdminSide setAdminLogged={setAdminLogged} />
                {
                    isLoading ?
                        <Loader adminLoader={true} /> :
                        <div className="right adminOurDetails">
                            <h2 className="title">
                                At a Glance
                            </h2>
                            <form>
                                <div className="fields">
                                    <div className="formField">
                                        <label className="fieldTitle" htmlFor="participants">
                                            Total Lifetime Participants
                                        </label>
                                        <input onChange={(e) => setOurDetails({ ...ourDetails, participants: parseInt(e.target.value) })} id="participants" value={ourDetails?.participants} type="number" placeholder="Enter Participants" />
                                    </div>
                                    <div className="formField">
                                        <label className="fieldTitle" htmlFor="exercisehours">
                                            Hours of Exercise
                                        </label>
                                        <input onChange={(e) => setOurDetails({ ...ourDetails, exercisehours: parseInt(e.target.value) })} id="exercisehours" value={ourDetails?.exercisehours} type="number" placeholder="Enter Hours of Exercise" />
                                    </div>
                                    <div className="formField">
                                        <label className="fieldTitle" htmlFor="classestaught">
                                            Classes Taught
                                        </label>
                                        <input onChange={(e) => setOurDetails({ ...ourDetails, classestaught: parseInt(e.target.value) })} id="classestaught" value={ourDetails?.classestaught} type="number" placeholder="Enter Total Classes" />
                                    </div>
                                </div>
                                <p className={isError ? "resMsg error" : "resMsg"}>
                                    {resMsg}
                                </p>
                                <button onClick={updateOurDetails}>
                                    Update
                                </button>
                            </form>
                        </div>
                }
            </div>
        </div>
    )
}

export default AdminOurDetails
