import React from 'react';
import founder from '../Assets/founder.png';
import founderFont from '../Assets/FounderFont.png'

const Founder = () => {
    return (
        <div className="founder">
            <h1 className="title">
                FOUNDER & LEAD TRAINER
            </h1>
            <div className="imgBox">
                <img loading="lazy" src={founder} alt="" />
                <div className="backgroundFonts">
                    <img loading="lazy" src={founderFont} alt="" />
                </div>
            </div>
            <h1 className="founderName">
                AMADOU GUEYE
            </h1>
            <p className="desc">
                Amadou is the founder and lead trainer at Forward Fitness. Excelling at track & field, wrestling, and soccer from an early age, his athletic journey took him to Syracuse University, where he earned Big East and ACC Athletic and Academic accolades and served as the track team’s captain from 2011-2014. After college, Amadou gained experience and further expanded his leadership skills working as an Educator and Head of Partnerships for a technology startup. Now with Forward Fitness, Amadou is able to bring his athletic experiences together with his passion for knowledge to create the ultimate community for individuals who are serious about moving themselves forward!
            </p>
            <a target="_blank" href="https://youtu.be/UogrW8jeFks" rel="noreferrer" className="btn learnmore">
                Learn More <ion-icon name="arrow-forward"></ion-icon>
            </a>
        </div>
    )
}

export default Founder