import React from 'react'
import slides from "../Assets/Frame.png";

const OverView = () => {
    return (
        <div className="overview">
            <div className="slideImg">
                <img loading="lazy" src={slides} alt="" />
            </div>
            <div className="insta">
                <a className="instaLink" rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/forwardfitness/?hl=en">
                    <ion-icon name="logo-instagram"></ion-icon>
                    Follow us on @forwardfitness
                </a>
            </div>
        </div>
    )
}

export default OverView;
