import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getDate } from '../Data'
import AdminSide from './AdminSide'
import AdminTop from './AdminTop'
import editBtn from "../Assets/editBtn.png";
import deleteBtn from "../Assets/deleteBtn.png";
import axios from 'axios'
import { deleteCohortApi, deleteSlotApi, getSinglePlanApi, updateCohortApi } from '../Config/Api'
import Loader from './Loader'

const AdminSingleCohort = ({ adminDetails, setAdminLogged }) => {
    const [curPlan, setCurPlan] = useState({ title: "", description: "", totalprice: "", numberofweeks: "", cohortnumber: "" });
    const [curSlots, setCurSlots] = useState([]);
    const [planId, setPlanId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [resMsg, setResMsg] = useState("");

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setPlanId(location.pathname.split("/")[3])
    }, [location]);

    useEffect(() => {
        const findCohort = async () => {
            try {
                if (planId) {
                    setIsLoading(true);
                    const res = await axios.get(getSinglePlanApi(planId), { withCredentials: true });
                    if (res.status === 200) {
                        setCurPlan(res?.data?.cohort);
                        setCurSlots(res?.data?.slots);
                        setIsLoading(false);
                    }
                }
            } catch (e) {
                setIsLoading(false);
                console.log(e);
            }
        }
        findCohort();

    }, [planId]);

    const validateData = () => {
        if (!curPlan.title || !curPlan.description || !curPlan.totalprice || !curPlan.numberofweeks || !curPlan.cohortnumber) {
            setIsError(true);
            setResMsg("Please fill out all the Fields ");
            return false;
        } else if (curPlan.title.length < 5) {
            setIsError(true);
            setResMsg("Title is very small");
            return false;
        } else if (curPlan.description.length < 20) {
            setIsError(true);
            setResMsg("Description is very small");
            return false;
        } else if (curPlan.totalprice < 0) {
            setIsError(true);
            setResMsg("Price must be positive");
            return false;
        } else if (curPlan.numberofweeks <= 0) {
            setIsError(true);
            setResMsg("Number of weeks should be greater than 0");
            return false;
        }
        setIsError(false);
        setResMsg("");
        return true;
    }


    const updatePlan = async (e) => {
        e.preventDefault();
        if (validateData()) {
            try {
                setIsLoading(true);
                const res = await axios.post(updateCohortApi(planId), curPlan, { withCredentials: true });
                if (res.status === 200) {
                    setIsError(false);
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
                setIsError(true);
                if (e.response.status === 400) {
                    setResMsg(e.response.data);
                } else if (e.response.status === 500) {
                    setResMsg(e.response.data);
                } else {
                    setResMsg("Sorry, Something went wrong - Please try after some time.");
                }
                console.log(e);
            }
        }
    }

    const deleteCohort = async () => {
        if (window.confirm("Are you sure want to Delete this Cohort ? ")) {
            try {
                setIsLoading(true);
                const res = await axios.post(deleteCohortApi(planId), curPlan, { withCredentials: true });

                if (res.status === 200) {
                    setIsLoading(false);
                    window.location.reload();
                }
            } catch (e) {
                setIsLoading(false);
                setIsError(true);
                if (e.response.status === 400) {
                    setResMsg(e.response.data);
                } else if (e.response.status === 500) {
                    setResMsg(e.response.data);
                } else {
                    setResMsg("Sorry, Something went wrong - Please try after some time.");
                }
                console.log(e);
            }
        }
    }

    const deleteSlot = async (slotid) => {
        if (window.confirm("Are you sure you want to delete this time slot?")) {
            try {
                setIsLoading(true);
                const res = await axios.post(deleteSlotApi(slotid), {
                    cohortid: planId
                }, { withCredentials: true })

                if (res.status === 200) {
                    setIsLoading(false);
                    setIsError(false);
                    window.location.reload();
                }

            } catch (e) {
                setIsLoading(false);
                setIsError(true);
                if (e.response.status === 400) {
                    setResMsg(e.response.data);
                } else if (e.response.status === 500) {
                    setResMsg(e.response.data);
                } else {
                    setResMsg("Sorry, Something went wrong - Please try after some time.");
                }
                console.log(e);
            }
        }
    }

    return (
        <div>
            <AdminTop adminDetails={adminDetails} />
            <div className="adminMainContent">
                <AdminSide setAdminLogged={setAdminLogged} />
                {
                    isLoading ?
                        <Loader adminLoader={true} /> :
                        <div className="adminSingleCohort right">
                            <div className="topCohortBar">
                                <h2 className="title">
                                    Cohort &gt; {curPlan?.title}
                                </h2>
                                <button onClick={deleteCohort} className="deleteCohort">
                                    Delete
                                </button>
                            </div>
                            <form>
                                <div className="formField">
                                    <label className="fieldTitle" htmlFor="programName">
                                        Program Name
                                    </label>
                                    <input onChange={(e) => setCurPlan({ ...curPlan, title: e.target.value })} value={curPlan?.title} id="programName" type="text" placeholder="Enter Program Name" />
                                </div>
                                <div className="formField">
                                    <label className="fieldTitle" htmlFor="username">
                                        Program Description (Add $ sign to seperate the paragraph)
                                    </label>
                                    <textarea onChange={(e) => setCurPlan({ ...curPlan, description: e.target.value })} value={curPlan?.description} id="username" type="text" placeholder="Enter Program Description" />
                                </div>
                                <div className="fields">
                                    <div className="formField">
                                        <label className="fieldTitle" htmlFor="username">
                                            Program Price (In USD)
                                        </label>
                                        <input onChange={(e) => setCurPlan({ ...curPlan, totalprice: e.target.value })} value={curPlan?.totalprice} id="programName" type="number" placeholder="Price" />
                                    </div>
                                    <div className="formField">
                                        <label className="fieldTitle" htmlFor="username">
                                            Number of Weeks
                                        </label>
                                        <input onChange={(e) => setCurPlan({ ...curPlan, numberofweeks: e.target.value })} value={curPlan?.numberofweeks} id="programName" type="number" placeholder="Number of Weeks" />
                                    </div>
                                    <div className="formField">
                                        <label className="fieldTitle" htmlFor="cohortNumber">
                                            Cohort Number
                                        </label>
                                        <input onChange={(e) => setCurPlan({ ...curPlan, cohortnumber: e.target.value })} value={curPlan?.cohortnumber} id="cohortNumber" type="number" placeholder="Enter Cohort Number" />
                                    </div>
                                </div>
                                <p className={isError ? "resMsg error" : "resMsg"}>
                                    {resMsg}
                                </p>
                                <button onClick={updatePlan}>
                                    Update
                                </button>
                            </form>

                            <div className="tableDiv" style={{ overflow: "auto" }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Slot No.</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Status</th>
                                            <th>Edit</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            curSlots?.map((slot, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {index + 1}
                                                    </td>
                                                    <td>
                                                        {getDate(slot?.startdate)}
                                                    </td>
                                                    <td>
                                                        {getDate(slot?.enddate)}
                                                    </td>
                                                    <td className={"status " + slot?.slotstatus}>
                                                        {slot?.slotstatus}
                                                    </td>
                                                    <td className="edit">
                                                        <button onClick={() => navigate(`/admin/cohort/${planId}/${slot._id}`)}>
                                                            <img src={editBtn} alt="" />
                                                        </button>
                                                    </td>
                                                    <td className="end">
                                                        <button onClick={() => deleteSlot(slot._id)}>
                                                            <img src={deleteBtn} alt="" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>

                                </table>
                            </div>

                            <div className="bottomCohortBar">
                                <button onClick={() => navigate(`/admin/cohort/${planId}/createslot`)} className="addSlot">
                                    Add Slot
                                </button>
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}

export default AdminSingleCohort
