import React from 'react'
import { useNavigate } from 'react-router-dom';
import backgroundFont from '../Assets/headerBgFonts.png'
import heroImg from '../Assets/heroImg.png';
import Navbar from './Navbar';

const HomeHeader = () => {
    const navigate = useNavigate();
    return (
        <div className="homeHeader">
            <Navbar />
            <div className="left">
                <img src={heroImg} loading="lazy" alt="" />
            </div>
            <div className="right">
                <h2 className="slogan">
                    A virtual fitness program so easy, all you have to do is show up.
                </h2>
                <p className="desc">
                    Sign up to achieve your health and wellness goals without leaving your home.
                </p>
                <button onClick={() => { navigate("/services") }} className="learnmore">
                    Take The Quiz <ion-icon name="arrow-forward"></ion-icon>
                </button>
            </div>
            <div className="backgroundFonts">
                <img loading="lazy" src={backgroundFont} alt="" />
            </div>
        </div>
    )
}

export default HomeHeader