import React from 'react'
import Chapters from '../Components/Chapters'
import CoreValues from '../Components/CoreValues'
import Founder from '../Components/Founder'
import Navbar from '../Components/Navbar'
import Trainers from '../Components/Trainers'
import Footer from "../Components/Footer";
import ScrollToTopArrow from '../Components/ScrollToTopArrow'

const AboutPage = () => {
    return (
        <div className="aboutpage" style={{ position: "relative" }}>
            <ScrollToTopArrow />
            <Navbar />
            <Chapters />
            <CoreValues />
            <Founder />
            <Trainers />
            <Footer />
        </div>
    )
}

export default AboutPage