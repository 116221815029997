import React, { useContext, useEffect, useState } from 'react'
import watermarkCohort1 from "../Assets/watermarkCohort1.png";
import watermarkCohort2 from "../Assets/watermarkCohort2.png";
import watermarkCohort3 from "../Assets/watermarkCohort3.png";
import watermarkCohort4 from "../Assets/watermarkCohort4.png";
import { planContext, quizContext } from "../App";
import { Link } from 'react-router-dom';
import Loader from "./Loader";
import plan1Img from "../Assets/plan1.png";
import plan2Img from "../Assets/plan2.png";
import plan3Img from "../Assets/plan3.png";
import plan4Img from "../Assets/plan4.png";
import axios from 'axios';
import { getSinglePlanApi } from '../Config/Api';
import { getDate, sortSlots } from '../Data';

const Plan = (props) => {
    const { planId, planIndex, suggestion, setnotifyPopUpActive, setconfirmPopUpActive, setShowSlots, showSlots } = props;

    const { quizCompleted, isNewUser } = useContext(quizContext);
    const { setUserSelectedPlan, setUserSelectedDates, setUserSelectedTimes } = useContext(planContext);
    const [curPlan, setCurPlan] = useState({ title: "", description: "", totalprice: "", numberofweeks: "" });
    const [curSlots, setCurSlots] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const planImages = [plan1Img, plan2Img, plan3Img, plan4Img];
    const watermarkes = [watermarkCohort1, watermarkCohort2, watermarkCohort3, watermarkCohort4];


    const slotSelected = (selectedPlan, selectedDateSlot, selectedTimeSlot) => {
        setUserSelectedPlan(null)
        setUserSelectedTimes(null)
        setUserSelectedDates(null)
        setconfirmPopUpActive(true)
        setUserSelectedPlan(selectedPlan)
        setUserSelectedDates(selectedDateSlot)
        setUserSelectedTimes(selectedTimeSlot)
    }

    const handleNotifyMe = (selectedPlan, selectedDateSlot) => {
        setnotifyPopUpActive(true);
        setUserSelectedPlan(selectedPlan);
        setUserSelectedDates(selectedDateSlot);
        // console.log(selectedDateSlot);
    }

    const checkTime = (t) => {
        let hour;
        if (t)
            hour = t.split(":")[0];
        return parseInt(hour) > 16
    }

    useEffect(() => {
        // console.log(planId);
        const getPlanDetail = async () => {
            setIsLoading(true);
            try {
                if (planId) {
                    const res = await axios.get(getSinglePlanApi(planId));
                    setCurPlan(res?.data?.cohort);
                    setCurSlots(res?.data?.slots.sort(sortSlots));
                    setIsLoading(false);
                }
            } catch (e) {
                console.log(e);
                setIsLoading(false);
            }
        }
        getPlanDetail();
    }, [planId]);

    return (
        isLoading ?
            <Loader /> :
            <div className="planContainer">
                <div className={isNewUser ? "newUserPlan planDetails" : "planDetails"}>
                    <p className="suggestion">
                        {suggestion}
                    </p>
                    <div className="planLeft">
                        <h2 className="title">
                            {curPlan?.title}
                        </h2>
                        <div className="serviceBgFontMobile">
                            <img loading="lazy" src={watermarkes[planIndex % 4]} alt="" />
                        </div>
                        {
                            curPlan?.description.split('$').map((desc, index) => (
                                <p key={index} className="desc">
                                    {desc}
                                </p>
                            ))
                        }
                        <Link to={!quizCompleted && "/services"}>
                            <button>
                                <div className="btnTop">
                                    <p className="btnTitle">
                                        {curPlan?.title}
                                    </p>
                                    <p className="price">
                                        ${curPlan?.totalprice / (curPlan?.numberofweeks * 5)}/Session
                                    </p>
                                </div>
                                <div className="btnBottom">
                                    <p className="btnDesc">
                                        Cohort: {curPlan?.numberofweeks}-Weeks, {curPlan?.numberofweeks * 5} Sessions
                                    </p>
                                    <p className="subPrice">
                                        <span>${curPlan?.totalprice}</span>Total
                                    </p>
                                </div>
                            </button>
                        </Link>
                        {
                            quizCompleted
                            &&
                            <div className="allSlotsContainer">
                                <h2 className="slotSlogan">
                                    Pick your prefered slots
                                </h2>
                                <div className="currentSlots">
                                    {
                                        curSlots?.map((curDateSlot, dateSlotIndex) => {
                                            return (
                                                <div className="currentSlot" key={`dateSlot${dateSlotIndex}`}>
                                                    <div className="daySlot">
                                                        <p className="dates">
                                                            {getDate(curDateSlot?.startdate)} - {getDate(curDateSlot?.enddate)}
                                                        </p>
                                                        <button onClick={() => {
                                                            if (curDateSlot.slotstatus === "Available") {
                                                                setShowSlots(showSlots === curDateSlot._id ? null : curDateSlot._id);
                                                            }
                                                            else if (curDateSlot.slotstatus === "Notify Me")
                                                                handleNotifyMe(curPlan, curDateSlot);
                                                        }} disabled={curDateSlot.slotstatus === "Closed"} className="btn">
                                                            {curDateSlot.slotstatus}
                                                        </button>
                                                    </div>
                                                    {
                                                        showSlots === curDateSlot._id &&
                                                        (
                                                            <div className="timeSlots">
                                                                {
                                                                    curDateSlot.timeslots.length > 0 &&
                                                                    curDateSlot.timeslots?.map((curTimeSlot, timeSlotIndex) => {
                                                                        return (
                                                                            <div className="timeSlot" key={"timeSlot" + timeSlotIndex}>
                                                                                <input onChange={() =>
                                                                                    slotSelected(curPlan, curDateSlot, curTimeSlot)
                                                                                } type="radio" name={`timeSlot${curPlan.id}`} id={`timeSlot${curPlan.id}${timeSlotIndex}`} />
                                                                                <label htmlFor={`timeSlot${curPlan.id}${timeSlotIndex}`} className="timeBox">
                                                                                    {
                                                                                        checkTime(curTimeSlot?.starttime)
                                                                                            ?
                                                                                            <ion-icon name="moon"></ion-icon>
                                                                                            :
                                                                                            <ion-icon name="sunny"></ion-icon>
                                                                                    }
                                                                                    <p>
                                                                                        {curTimeSlot.starttime} to {
                                                                                            curTimeSlot.endtime
                                                                                        } EST
                                                                                    </p>
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <div className="planRight">
                        <img loading="lazy" src={planImages[planIndex % 4]} alt="" />
                    </div>
                </div>
                <div className="serviceBgFont">
                    <img loading="lazy" src={watermarkes[planIndex % 4]} alt="" />
                </div>
            </div>
    )
}

export default Plan