import React from 'react'
import faqBgFont from "../Assets/faqBgFont.png";
import { Link } from "react-router-dom";

const FAQ = () => {
    const faqQuestions = [
        {
            que: "I had an injury in the past, can I still work out?",
            ans: "Absolutely. At FF we do our best to make the workouts appropriate and accessible for everyone. Please reach out directly to our Founder, Amadou, if you have any questions or concerns."
        },
        {
            que: "I’ve never worked out before, will I be able to keep up?",
            ans: "Absolutely. We have built FF to be appropriate and accessible for everyone. Whether you are one of our professional athlete clients or someone who has never worked out before, we have a plan for you! Take the quiz above to find the right program for you!"
        },
        {
            que: "What equipment do I need?",
            ans: "At FF, we don’t believe that you must have bulky, expensive gym equipment in order to get a great workout. We always strive to make the barrier to entry low. That is why the vast majority of our 5-week classes don’t require any equipment. We believe that your body weight is plenty to lift, twist, squat, lounge, and push."
        },
        {
            que: "I’m a new mom, can I still work out with FF?",
            ans: "Yes! New mothers have just gone through a very traumatic experience delivering a baby. If your doctor has cleared you to begin physical activity, FF has been an incredible program for women to join and get back or gain strength! Don’t just take our word for it,",
            ans2: " to learn about this mother’s postpartum experience.",
            url: "https://youtu.be/CC3r1WRZ05w",
            urltext: "click here"
        },
        {
            que: "What if I miss a session?",
            ans: "At FF we want to promote accountability and consistency. We also know that life happens. We are very flexible with options if you miss a session. Connect with your trainer or the Founder, Amadou, in order to make up classes."
        },
        {
            que: "Do you offer free trials?",
            ans: "Yes, we do have free trials. We periodically do free classes on weekends. Feel free to sign up.",
            url: "https://www.eventbrite.com/o/forward-fitness-42146765523",
            urltext: "here."
        },
        {
            que: "Can I get a refund?",
            ans: "At FF we do our best to accommodate our partners. Generally speaking, we don’t offer refunds but if some unforeseen circumstances arise contact our founder, Amadou, he will be able to help you."
        },
        {
            que: "Do you offer corporate partnerships?",
            ans: "Yes, we do! We love working with large groups of motivated people. We offer completely customizable packages for groups.",
            ans2: " directly to our Founder, Amadou for more information.",
            link: "/contact",
            linktext: "Reach out"
        },
        {
            que: "None of the available times work for me but I’d love to work out with FF, do you take time slot recommendations?",
            ans: "Absolutely. As we grow we hope to have all classes available throughout the day. Until then, please feel free to recommend time slots by emailing them to amadou@foreverforward.org"
        },
        {
            que: "Can I break up payments and pay on a weekly or daily basis?",
            ans: "Yes, you can. Everyone’s budget is different. Sometimes it’s not easy to pay upfront for our services. At FF we strive to make budgeting accessible. We do have weekly payment structures. Reach out to our Founder, Amadou to learn more."
        },
        {
            que: "Do I need to prep before the class?",
            ans: "No need to prep ahead of class! At FF we believe that each program should be self contained. Meaning nobody needs to prepare outside of class prior to joining. Come as you are!"
        }
    ]

    return (
        <div className="faqSection">
            <h1 className="title">
                Frequently Asked Questions
            </h1>

            <div className="questionsContainer">
                {
                    faqQuestions.map((faqQuestion, index) => {
                        return (
                            <details key={index}>
                                <summary>
                                    <p className="que">
                                        {faqQuestion.que}
                                    </p>
                                    <p className="btn">
                                        +
                                    </p>
                                </summary>
                                <p className="ans">
                                    {faqQuestion.ans}
                                    {
                                        faqQuestion.url &&
                                        <a target="_blank" rel="noreferrer" href={faqQuestion.url}>
                                            {faqQuestion.urltext}
                                        </a>
                                    }
                                    {
                                        faqQuestion.link &&
                                        <Link to={faqQuestion.link} style={{ margin: "0" }}>
                                            {faqQuestion.linktext}
                                        </Link>
                                    }
                                    {faqQuestion.ans2}
                                </p>
                            </details>
                        )
                    })
                }
            </div >
            <div className="faqBgFont">
                <img loading="lazy" src={faqBgFont} alt="" />
            </div>
        </div>
    )
}

export default FAQ