import React from 'react'
import combineImg from "../Assets/combine.png"
import whoarewe from "../Assets/WhoAreWe.png";
import whoareweMobile from "../Assets/whoAreWeMobile.png";
// import combine from "../Assets/combine.png"
import { useNavigate } from 'react-router-dom';

const WhoAreWe = () => {
    const navigate = useNavigate();
    return (
        <div className="whoAreWe">
            <div className="whoareweBgFont">
                <img loading="lazy" src={whoarewe} alt="" />
            </div>
            <div className="whoareweBgFontMobile">
                <img loading="lazy" src={whoareweMobile} alt="" />
            </div>
            <div className="left">
                <div className="imgbox">
                    <img loading="lazy" className="img1" src={combineImg} alt="" />
                </div>
            </div>
            <div className="right">
                <h2 className="title">
                    Who Are We
                </h2>
                <div className="desc">
                    <p>
                        Forward Fitness is a community of hard-working individuals looking to work out and have fun at the same time. We have a variety of 5-week programs designed to get you moving for 30-minutes Monday through Friday. Our curated fitness programs are tailored for everyone to achieve their fitness goals, no matter where you are in your fitness journey, and where you are in the world!
                    </p>
                    <p>
                        We know fitness can be daunting, which is why we make it so easy for you to have it all with Forward Fitness: the plan, the support, and the accountability. All you have to do is show up! You will feel right at home, quite literally, as you make measurable progress under the guidance of one of our knowledgeable trainers coaching you live every workout.
                    </p>
                </div>

                <button onClick={() => { navigate("/about") }} className="learnmore">
                    Learn More <ion-icon name="arrow-forward"></ion-icon>
                </button>
            </div>

        </div>
    )
}

export default WhoAreWe