import React, { useContext, useEffect, useState } from 'react';
import FAQ from '../Components/FAQ';
import Navbar from '../Components/Navbar';
import Quiz from '../Components/Quiz';
import Footer from '../Components/Footer';
import ScrollToTopArrow from '../Components/ScrollToTopArrow';
import { quizContext } from '../App';
import AllPlansService from '../Components/AllPlansService';

const ServicesPage = () => {
    const [quizStarted, setQuizStarted] = useState(false);
    const { setQuizCompleted } = useContext(quizContext);

    useEffect(() => {
        setQuizCompleted(false);
    }, []);


    return (
        <div className="servicesPage" style={{ position: "relative" }}>
            <ScrollToTopArrow />
            <Navbar />
            <Quiz quizStarted={quizStarted} setQuizStarted={setQuizStarted} />
            {
                !quizStarted &&
                <AllPlansService />
            }
            {
                !quizStarted &&
                <FAQ />
            }
            <Footer />
        </div>
    )
}

export default ServicesPage