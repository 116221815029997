import React, { useState } from 'react'
import chap1 from "../Assets/chap1.png";
import chap2 from "../Assets/chap2.png";
import chap3 from "../Assets/chap3.png";
import chap4 from "../Assets/chap4.png";

const Chapters = () => {
    const [showDetails, setShowDetails] = useState(0);
    return (
        <div className="chapters">
            <div className={showDetails === 1 ? "hovered chapter chap1" : "chapter chap1"}>
                <div onClick={() => { setShowDetails(showDetails === 1 ? 0 : 1) }} className="left">
                    <div className="details">
                        {/* <h2>
                            I
                        </h2> */}
                        <h3>
                            THE CHALLENGE
                        </h3>
                    </div>
                </div>
                <div className="right">
                    <div className="imgBox">
                        <img loading="lazy" src={chap1} alt="" />
                    </div>
                    <div className="storyBox">
                        <h2 className="title">
                            THE CHALLENGE
                        </h2>
                        <p className="desc">
                            Forward Fitness founder, Amadou Gueye, had been an athlete all his life. Running track landed him a scholarship at Syracuse University where he became captain for 3 years, which then led to his professional track career. He was training consistently to be able to qualify for the 2018 World Championships.
                            But one day, his life changed. He was in a car accident that caused a devastating, career-ending knee injury. With his life turned upside down, Amadou was left feeling frustrated, upset, and lost. He experienced rapid weight gain caused by a negative mindset and frustration with his inability to work out at the same rate as before.
                        </p>
                    </div>
                </div>
            </div>
            <div className={showDetails === 2 ? "hovered chapter chap2" : "chapter chap2"}>
                <div onClick={() => { setShowDetails(showDetails === 2 ? 0 : 2) }} className="left">
                    <div className="details">
                        {/* <h2>
                            II
                        </h2> */}
                        <h3>
                            THE BEGINNING
                        </h3>
                    </div>
                </div>
                <div className="right">
                    <div className="imgBox">
                        <img loading="lazy" src={chap2} alt="" />
                    </div>
                    <div className="storyBox">
                        <h2 className="title">
                            THE BEGINNING
                        </h2>
                        <p className="desc">
                            As the COVID-19 pandemic rocked the world, Amadou found himself in his tiny 600-sq ft apartment in Austin, Texas - locked down with nowhere to go. It was at this moment that Forward Fitness was born.
                        </p>
                    </div>
                </div>
            </div>
            <div className={showDetails === 3 ? "hovered chapter chap3" : "chapter chap3"}>
                <div onClick={() => { setShowDetails(showDetails === 3 ? 0 : 3) }} className="left">
                    <div className="details">
                        {/* <h2>
                            III
                        </h2> */}
                        <h3>
                            THE MOTIVATION
                        </h3>
                    </div>
                </div>
                <div className="right">
                    <div className="imgBox">
                        <img loading="lazy" src={chap3} alt="" />
                    </div>
                    <div className="storyBox">
                        <h2 className="title">
                            THE MOTIVATION
                        </h2>
                        <p className="desc">
                            Amadou received a phone call from a college athlete mentee, asking for help in building his core strength during the pandemic. Amadou decided to create a workout routine that he could follow from the convenience of his apartment that would not require any gym equipment. Amadou started to work out along with his trainee to test out his program. Throughout the course of the next five weeks, as Amadou and his trainee worked out using this program built on the principles of hard work, consistency, and accountability, they saw their bodies transform.
                            <br />
                            Amadou also had a positive mindset shift, and his general love of life and working out come back along with an idea to take this program forward!
                            {/* <br />
                            Amadou received a phone call from a college athlete he works with. His trainer identified a weak core as the source of his back pain that ailed him during the basketball season. Amadou decided to create a customized core strength and stability program that they could both follow from the convenience of their apartment. Throughout the course of the next five weeks, as Amadou and his trainee worked out using this program, they saw their bodies transform.
                            <br />
                            Amadou also had a positive mindset shift, and his general love of life and working out come back along with an idea to export this program to the world! */}
                        </p>
                    </div>
                </div>
            </div>
            <div className={showDetails === 4 ? "hovered chapter chap4" : "chapter chap4"}>
                <div onClick={() => { setShowDetails(showDetails === 4 ? 0 : 4) }} className="left">
                    <div className="details">
                        {/* <h2>
                            IV
                        </h2> */}
                        <h3>
                            THE FUTURE
                        </h3>
                    </div>
                </div>
                <div className="right">
                    <div className="imgBox">
                        <img loading="lazy" src={chap4} alt="" />
                    </div>
                    <div className="storyBox">
                        <h2 className="title">
                            THE FUTURE
                        </h2>
                        <p className="desc">
                            Wanting to help people that were feeling stuck in their own physical health journeys, Amadou initially launched Forward Fitness with 11 clients. The company has since grown to help hundreds of people, all subscribing to the same founding principles. If you vow to work hard, remain consistent, and hold yourself accountable, you can achieve anything!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Chapters