import React, { useContext, useEffect, useState } from 'react'
import service1 from "../Assets/service1.png";
import service2 from "../Assets/service2.png";
import service3 from "../Assets/service3.png";
import service4 from "../Assets/service4.png";
import { useNavigate } from "react-router-dom";
import ourServicesBgFont from "../Assets/ourServicesBgFont.png";
import ourServicesBgFontMobile from "../Assets/ourServicesMobile.png";
import { planContext } from '../App';

const OurServices = () => {
    const [activePlan, setActivePlan] = useState(0);
    const [prePlan, setPrePlan] = useState(3);
    const [nextPlan, setNextPlan] = useState(1);

    const { allCohorts } = useContext(planContext);

    const navigate = useNavigate();

    const plans = [
        {
            title: "CORE ACCELERATOR V1",
            image: service1,
            desc: "Our Flagship Program focused on strengthening and stabilizing your Core."
        },
        {
            title: "CORE ACCELERATOR V2",
            image: service2,
            desc: "Building on Core V2 principles with more intense ab focused workouts."
        },
        {
            title: "FULL BODY V1",
            image: service3,
            desc: "We get the whole body moving to reach your goals."
        },
        {
            title: "FULL BODY V2",
            image: service4,
            desc: "Our most advanced class for our most dedicated clients."
        }
    ]

    useEffect(() => {
        if (activePlan === 0)
            setPrePlan(plans.length - 1);
        else
            setPrePlan(activePlan - 1);

        if (activePlan === (plans.length - 1))
            setNextPlan(0);
        else
            setNextPlan(activePlan + 1);
    }, [activePlan]);

    return (
        <div className="ourServices">
            <div className="serviceDetailBox">
                <h2 className="title">
                    Our Services
                </h2>
                <p className="desc">
                    Each of our 5 week cohorts are specifically designed to help you get stronger by building on the previous week’s progress. You will get stronger and closer to your goals 1 week at a time.
                </p>
            </div>
            <div className="backgroundFonts">
                <img loading="lazy" src={ourServicesBgFont} alt="" />
            </div>
            <div className="backgroundFontsMobile">
                <img loading="lazy" src={ourServicesBgFontMobile} alt="" />
            </div>
            <div className="serviceContainer">
                <div className="slider">
                    <div className="preAllSlides">
                        {
                            plans.map((plan, index) => {
                                return (
                                    <div key={index} className={prePlan === index ? "slide preSlide" : "slide"}>
                                        <img loading="lazy" src={plan.image} alt="" />
                                        <div className="slideDesc">
                                            <p>
                                                {plan.desc}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="activeAllSlides">
                        {
                            plans.map((plan, index) => {
                                return (
                                    <div key={index} className={activePlan === index ? "slide activeSlide" : "slide"}>
                                        <img loading="lazy" src={plan.image} alt="" />
                                        <div className="slideDesc">
                                            <p>
                                                {plan.desc}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="nextAllSlides">
                        {
                            plans.map((plan, index) => {
                                return (
                                    <div key={index} className={nextPlan === index ? "slide nextSlide" : "slide"}>
                                        <img loading="lazy" src={plan.image} alt="" />
                                        <div className="slideDesc">
                                            <p>
                                                {plan.desc}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="slideController">
                    {
                        allCohorts?.map((plan, index) => {
                            if (index < 4)
                                return (
                                    <div key={index} className="controller">
                                        <p onClick={() => setActivePlan(index)} className={activePlan === index ? "active" : ""}>
                                            {plan.title}
                                        </p>
                                    </div>
                                )
                            else
                                return <></>
                        })
                    }
                    <button onClick={() => { navigate(`/services/#plan${activePlan}`) }} className="learnmore">
                        Book A Class<ion-icon name="arrow-forward"></ion-icon>
                    </button>
                </div>
            </div>

        </div>
    )
}

export default OurServices;