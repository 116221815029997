import axios from 'axios';
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { planContext, quizContext } from '../App';
// import AllPlans from '../Components/AllPlans';
import Footer from '../Components/Footer';
import Navbar from '../Components/Navbar';
import Plan from '../Components/Plan';
import ScrollToTopArrow from '../Components/ScrollToTopArrow';
import { createNotificationAlertApi } from '../Config/Api';
import { getDate } from '../Data';

const SuggestPlan = () => {
    const { isNewUser, prevPlanIndex } = useContext(quizContext);
    const { userSelectedPlan, userSelectedDates, userSelectedTimes, allCohorts } = useContext(planContext);
    const navigate = useNavigate();

    // Notify Me Components
    const [notifyPopUpActive, setnotifyPopUpActive] = useState(false);
    const [notifyEmail, setNotifyEmail] = useState("");
    const [notificationOn, setNotificationOn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Slot Confirmation Components
    const [confirmPopUpActive, setconfirmPopUpActive] = useState(false);


    const [showSlots, setShowSlots] = useState(null);


    const handleNotifyMe = async () => {
        try {
            setIsLoading(true);
            const res = await axios.post(createNotificationAlertApi, {
                planid: userSelectedPlan._id,
                planname: userSelectedPlan.title,
                slotid: userSelectedDates._id,
                useremail: notifyEmail.toLowerCase()
            })
            if (res.status === 201) {
                setIsLoading(false);
                setNotificationOn(true);
                console.log(res);
            }
        } catch (e) {
            setIsLoading(false);
            console.log(e);
        }
    }

    return (
        <div className="suggestPlan" style={{ position: "relative" }}>
            <ScrollToTopArrow />
            <Navbar />
            {
                notifyPopUpActive &&
                (
                    <div className="popUpLayer">
                        {
                            !notificationOn ?
                                <div className="popUpBox">
                                    <ion-icon onClick={() => setnotifyPopUpActive(false)} name="close-circle-outline"></ion-icon>
                                    <h2 className="title">
                                        Notify Me
                                    </h2>
                                    <p className="slogan">
                                        KEEP ME POSTED
                                    </p>
                                    <p className="desc">
                                        You'll be the first to know when we release the time slots!
                                    </p>
                                    <input value={notifyEmail} onChange={(e) => setNotifyEmail(e.target.value.toLowerCase())} type="email" placeholder="Enter Email Address" />
                                    <button disabled={isLoading} onClick={handleNotifyMe}>
                                        Notify Me
                                    </button>
                                </div>
                                :
                                <div className="popUpBox">
                                    <ion-icon onClick={() => setnotifyPopUpActive(false)} name="close-circle-outline"></ion-icon>
                                    <h2 className="title">
                                        Notify me
                                    </h2>
                                    <p className="slogan">
                                        YOR’RE ALL SET
                                    </p>
                                    <p className="desc">
                                        If classes goes live, we’ll email you at <br /> {notifyEmail}
                                    </p>
                                    <button onClick={
                                        () => {
                                            setnotifyPopUpActive(false);
                                            navigate("/")
                                        }
                                    }>
                                        Continue Exploring
                                    </button>
                                </div>
                        }
                    </div>
                )
            }
            {
                confirmPopUpActive &&
                (
                    <div className="popUpLayer">
                        <div className="popUpBox">
                            <ion-icon onClick={() => setconfirmPopUpActive(false)} name="close-circle-outline"></ion-icon>
                            <h2 className="title">
                                {userSelectedPlan?.title}
                            </h2>
                            <p className="subTitle">
                                Dates
                            </p>
                            <p className="value">
                                {`${getDate(userSelectedDates?.startdate)} - ${getDate(userSelectedDates?.enddate)}`}
                            </p>
                            <p className="subTitle">
                                Time Slots
                            </p>
                            <p className="value">
                                {userSelectedTimes.starttime} to {userSelectedTimes.endtime} EST
                            </p>
                            <button onClick={
                                () => {
                                    setconfirmPopUpActive(false);
                                    navigate("/services/userdetails");
                                }
                            }>
                                Confirm
                            </button>
                        </div>
                    </div>
                )
            }
            {
                isNewUser ?
                    <div className="suggestPlan">
                        <h2 className="quizCompleteSlogan">
                            We're excited for your Forward Fitness journey! <br />
                            We recommend the following program:
                        </h2>
                        <div className="allplans">
                            <Plan
                                setconfirmPopUpActive={setconfirmPopUpActive}
                                setnotifyPopUpActive={setnotifyPopUpActive}
                                planId={(allCohorts.filter((cohort) => cohort.cohortnumber === 1))[0]._id}
                                planIndex={0}
                                showSlots={showSlots}
                                setShowSlots={setShowSlots}
                            />
                            <Plan
                                setconfirmPopUpActive={setconfirmPopUpActive}
                                setnotifyPopUpActive={setnotifyPopUpActive}
                                planId={(allCohorts.filter((cohort) => cohort.cohortnumber === 3))[0]._id}
                                planIndex={2}
                                showSlots={showSlots}
                                setShowSlots={setShowSlots}
                            />
                        </div>
                    </div>
                    :
                    <div className="suggestPlan">
                        <h2 className="quizCompleteSlogan"> Great work in {`${(allCohorts.filter((cohort) => cohort.cohortnumber === prevPlanIndex))[0].title}`}! <br />
                            We recommend the following program:</h2>
                        {/* <AllPlans
                            setconfirmPopUpActive={setconfirmPopUpActive}
                            setnotifyPopUpActive={setnotifyPopUpActive}
                            suggestion={"You can retake this cohort if you want to reinforce your learning and get stronger"}
                            suggestion2={"You can move forward to this cohort if you’re ready to take the next step in your fitness journey"}
                            showSlots={showSlots}
                            setShowSlots={setShowSlots}
                        /> */}

                        {
                            prevPlanIndex === 1 &&
                            <div className='allplans'>
                                <Plan
                                    setconfirmPopUpActive={setconfirmPopUpActive}
                                    setnotifyPopUpActive={setnotifyPopUpActive}
                                    planIndex={1}
                                    planId={(allCohorts.filter((cohort) => cohort.cohortnumber === prevPlanIndex + 1))[0]._id}
                                    suggestion="You can move forward to this cohort if you’re ready to take the next step in your fitness journey"
                                    setShowSlots={setShowSlots}
                                    showSlots={showSlots}
                                />
                                <Plan
                                    setconfirmPopUpActive={setconfirmPopUpActive}
                                    setnotifyPopUpActive={setnotifyPopUpActive}
                                    planIndex={2}
                                    planId={(allCohorts.filter((cohort) => cohort.cohortnumber === prevPlanIndex + 2))[0]._id}
                                    suggestion="You can move forward to this cohort if you’re up for a challenge"
                                    setShowSlots={setShowSlots}
                                    showSlots={showSlots}
                                />
                                <Plan
                                    setconfirmPopUpActive={setconfirmPopUpActive}
                                    setnotifyPopUpActive={setnotifyPopUpActive}
                                    planIndex={0}
                                    planId={(allCohorts.filter((cohort) => cohort.cohortnumber === prevPlanIndex))[0]._id}
                                    suggestion="You can retake this cohort if you want to reinforce your learning and get stronger"
                                    setShowSlots={setShowSlots}
                                    showSlots={showSlots}
                                />
                            </div>
                        }
                        {
                            prevPlanIndex === 2 &&
                            <div className='allplans'>
                                <Plan
                                    setconfirmPopUpActive={setconfirmPopUpActive}
                                    setnotifyPopUpActive={setnotifyPopUpActive}
                                    planIndex={2}
                                    planId={(allCohorts.filter((cohort) => cohort.cohortnumber === prevPlanIndex + 1))[0]._id}
                                    suggestion="You can move forward to this cohort if you’re ready to take the next step in your fitness journey"
                                    setShowSlots={setShowSlots}
                                    showSlots={showSlots}
                                />
                                <Plan
                                    setconfirmPopUpActive={setconfirmPopUpActive}
                                    setnotifyPopUpActive={setnotifyPopUpActive}
                                    planIndex={1}
                                    planId={(allCohorts.filter((cohort) => cohort.cohortnumber === prevPlanIndex))[0]._id}
                                    suggestion="You can retake this cohort if you want to reinforce your learning and get stronger"
                                    setShowSlots={setShowSlots}
                                    showSlots={showSlots}
                                />
                            </div>
                        }
                        {
                            prevPlanIndex === 3 &&
                            <div className='allplans'>
                                <Plan
                                    setconfirmPopUpActive={setconfirmPopUpActive}
                                    setnotifyPopUpActive={setnotifyPopUpActive}
                                    planIndex={2}
                                    planId={(allCohorts.filter((cohort) => cohort.cohortnumber === prevPlanIndex))[0]._id}
                                    suggestion="You can move forward to this cohort if you’re ready to take the next step in your fitness journey"
                                    setShowSlots={setShowSlots}
                                    showSlots={showSlots}
                                />
                                <Plan
                                    setconfirmPopUpActive={setconfirmPopUpActive}
                                    setnotifyPopUpActive={setnotifyPopUpActive}
                                    planIndex={3}
                                    planId={(allCohorts.filter((cohort) => cohort.cohortnumber === prevPlanIndex + 1))[0]._id}
                                    suggestion="You can move forward to this cohort if you’re up for a challenge"
                                    setShowSlots={setShowSlots}
                                    showSlots={showSlots}
                                />
                                <Plan
                                    setconfirmPopUpActive={setconfirmPopUpActive}
                                    setnotifyPopUpActive={setnotifyPopUpActive}
                                    planIndex={1}
                                    planId={(allCohorts.filter((cohort) => cohort.cohortnumber === prevPlanIndex - 1))[0]._id}
                                    suggestion="You can retake this cohort if you want to reinforce your learning and get stronger"
                                    setShowSlots={setShowSlots}
                                    showSlots={showSlots}
                                />
                            </div>
                        }

                        {
                            prevPlanIndex === 4 &&
                            <div className='allplans'>
                                <Plan
                                    setconfirmPopUpActive={setconfirmPopUpActive}
                                    setnotifyPopUpActive={setnotifyPopUpActive}
                                    planIndex={3}
                                    planId={(allCohorts.filter((cohort) => cohort.cohortnumber === prevPlanIndex))[0]._id}
                                    suggestion="You can retake this cohort if you want to reinforce your learning and get stronger"
                                    setShowSlots={setShowSlots}
                                    showSlots={showSlots}
                                />
                                <Plan
                                    setconfirmPopUpActive={setconfirmPopUpActive}
                                    setnotifyPopUpActive={setnotifyPopUpActive}
                                    planIndex={2}
                                    planId={(allCohorts.filter((cohort) => cohort.cohortnumber === prevPlanIndex - 1))[0]._id}
                                    suggestion="You can retake this cohort if you want to reinforce your learning and get stronger"
                                    setShowSlots={setShowSlots}
                                    showSlots={showSlots}
                                />
                                <Plan
                                    setconfirmPopUpActive={setconfirmPopUpActive}
                                    setnotifyPopUpActive={setnotifyPopUpActive}
                                    planIndex={1}
                                    planId={(allCohorts.filter((cohort) => cohort.cohortnumber === prevPlanIndex - 2))[0]._id}
                                    suggestion="You can retake this cohort if you want to reinforce your learning and get stronger"
                                    setShowSlots={setShowSlots}
                                    showSlots={showSlots}
                                />
                            </div>
                        }

                        {/* <Plan
                            setconfirmPopUpActive={setconfirmPopUpActive}
                            setSelectedDateSlot={setSelectedDateSlot}
                            setSelectedPlan={setSelectedPlan}
                            setnotifyPopUpActive={setnotifyPopUpActive}
                            key={index}
                            planIndex={index}
                            planId={planDetail._id}
                            suggestion={planDetail.cohortnumber <= prevPlanIndex ? suggestion : suggestion2}
                            setShowSlots={setShowSlots}
                            showSlots={showSlots}
                        /> */}
                    </div>
            }
            <Footer />
        </div>
    )
}

export default SuggestPlan;