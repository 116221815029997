import axios from 'axios'
import React, { useState } from 'react'
import AdminSide from '../Components/AdminSide'
import AdminTop from '../Components/AdminTop'
import { updateAdminDataApi, updatepasswordApi } from '../Config/Api'
import Loader from './Loader'

const AdminSettings = ({ adminDetails, setAdminDetails, setAdminLogged }) => {
    const [passwordData, setPasswordData] = useState({ curPassword: "", newPassword: "", retypePassword: "" })
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [resMsg, setResMsg] = useState("");
    const [isError2, setIsError2] = useState(false);
    const [resMsg2, setResMsg2] = useState("");

    const validateData = () => {
        if (!adminDetails.username || !adminDetails.email) {
            setIsError(true);
            setResMsg("Please fill out all the Fields ");
            return false;
        } else if (adminDetails.username.length < 5) {
            setIsError(true);
            setResMsg("Please Enter Valid Username (Minimum 5 characters)");
            return false;
        } else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(adminDetails.email))) {
            setIsError(true);
            setResMsg("Please Enter Valid Email Address");
            return false;
        }
        setIsError(false);
        setResMsg("");
        return true;
    }

    const validateData2 = () => {
        if (!passwordData.curPassword || !passwordData.newPassword || !passwordData.retypePassword) {
            setIsError2(true);
            setResMsg2("Please fill out all the Fields ");
            return false;
        } else if (passwordData.newPassword.length < 6) {
            setIsError2(true);
            setResMsg2("Password length is short (Minimum 6 characters required)");
            return false;
        } else if (passwordData.newPassword !== passwordData.retypePassword) {
            setIsError2(true);
            setResMsg2("New password and retype new password must be same");
            return false;
        }
        setIsError2(false);
        setResMsg2("");
        return true;
    }

    const updateAdminData = async (e) => {
        e.preventDefault();
        if (validateData()) {
            try {
                setIsLoading(true);
                const res = await axios.post(updateAdminDataApi, adminDetails, { withCredentials: true });
                if (res.status === 200) {
                    setIsLoading(false);
                    setIsError(false);
                    // setResMsg(res.data);
                    setAdminDetails(res.data);
                    setResMsg("Details Updated successfully")
                }
            } catch (e) {
                setIsError(true);
                setIsLoading(false);
                if (e.response.status === 400) {
                    setResMsg(e.response.data);
                } else if (e.response.status === 500) {
                    setResMsg(e.response.data);
                } else {
                    setResMsg("Sorry, Something went wrong - Please try after some time.");
                }
                // console.log(e);
            }
        }
    }

    const updatePassword = async (e) => {
        e.preventDefault();
        if (validateData2()) {
            try {
                setIsLoading(true);
                const res = await axios.post(updatepasswordApi, passwordData, { withCredentials: true });

                if (res.status === 200) {
                    setIsError2(false);
                    setPasswordData({ curPassword: "", newPassword: "", retypePassword: "" })
                    setIsLoading(false);
                    setResMsg2("Password Updated Successfully");
                }
            } catch (e) {
                setIsError2(true);
                setIsLoading(false);
                if (e.response.status === 400) {
                    setResMsg2(e.response.data);
                } else if (e.response.status === 500) {
                    setResMsg2(e.response.data);
                } else {
                    setResMsg2("Sorry, Something went wrong - Please try after some time.");
                }
                // console.log(e);
            }
        }
    }

    return (
        <div>
            <AdminTop adminDetails={adminDetails} />
            <div className="adminMainContent">
                <AdminSide setAdminLogged={setAdminLogged} />
                {
                    isLoading ?
                        <Loader adminLoader={true} /> :
                        <div className="right adminSettings">
                            <h2 className="title">
                                Settings
                            </h2>
                            <form>
                                <div className="fields">
                                    <div className="formField">
                                        <label className="fieldTitle" htmlFor="username">
                                            Update Username
                                        </label>
                                        <input onChange={(e) => setAdminDetails({ ...adminDetails, username: e.target.value })} id="username" value={adminDetails?.username} type="text" placeholder="Enter your Username" />
                                    </div>
                                    <div className="formField">
                                        <label className="fieldTitle" htmlFor="email">
                                            Update Email
                                        </label>
                                        <input onChange={(e) => setAdminDetails({ ...adminDetails, email: e.target.value.toLowerCase() })} id="email" value={adminDetails?.email} type="text" placeholder="Enter your Email" />
                                    </div>
                                </div>
                                <p className={isError ? "resMsg error" : "resMsg"}>
                                    {resMsg}
                                </p>
                                <button onClick={updateAdminData}>
                                    Update
                                </button>
                            </form>

                            <form className="form2">
                                <label className="fieldTitle">
                                    Update Password
                                </label>
                                <div className="fields">
                                    <div className="formField">
                                        <input value={passwordData.curPassword} onChange={(e) => setPasswordData({ ...passwordData, curPassword: e.target.value })} type="password" placeholder="Enter Current Password" />
                                    </div>
                                    <div className="formField">
                                        <input value={passwordData.newPassword} onChange={(e) => setPasswordData({ ...passwordData, newPassword: e.target.value })} type="password" placeholder="Enter New Password" />
                                    </div>
                                    <div className="formField">
                                        <input value={passwordData.retypePassword} onChange={(e) => setPasswordData({ ...passwordData, retypePassword: e.target.value })} type="password" placeholder="Confirm New Password" />
                                    </div>
                                </div>
                                <p className={isError2 ? "resMsg error" : "resMsg"}>
                                    {resMsg2}
                                </p>
                                <button onClick={updatePassword}>
                                    Update
                                </button>
                            </form>
                        </div>
                }
            </div>
        </div>
    )
}

export default AdminSettings    
