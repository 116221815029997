import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Footer from '../Components/Footer'
import Highlight from '../Components/Highlight'
import HomeHeader from '../Components/HomeHeader'
import OfferBanner from '../Components/OfferBanner'
import OurServices from '../Components/OurServices'
import OverView from '../Components/OverView'
import ScrollToTopArrow from '../Components/ScrollToTopArrow'
import Subscribe from '../Components/Subscribe'
import Testimonials from '../Components/Testimonials'
import WhoAreWe from '../Components/WhoAreWe'
import { fetchActiveBannerApi } from '../Config/Api'

const HomePage = () => {
    const [bannerData, setBannerData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const findActiveBanner = async () => {
            setIsLoading(true);
            const res = await axios.get(fetchActiveBannerApi);

            if (res.status === 200 && res.data.success === true) {
                const { success, ...others } = res.data;
                setBannerData(others);
                setIsLoading(false);
            }
        }
        findActiveBanner();
    }, []);

    return (
        <div className="homePage" style={{ position: "relative" }}>
            <ScrollToTopArrow />
            {
                !isLoading &&
                <OfferBanner bannerData={bannerData} />
            }
            <HomeHeader />
            <WhoAreWe />
            <Highlight />
            <OurServices />
            <Testimonials />
            <Subscribe />
            <OverView />
            <Footer />
        </div>
    )
}

export default HomePage;