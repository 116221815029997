import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { getSingleSlotApi, updateSlotApi } from '../Config/Api';
import AdminSide from './AdminSide'
import AdminTop from './AdminTop'
import Loader from './Loader';
import uuid from 'react-uuid';

const AdminUpdateSlot = ({ adminDetails, setAdminLogged }) => {
    // const [curPlan, setCurPlan] = useState({});
    const [planId, setPlanId] = useState("");
    const [slotId, setSlotId] = useState("");
    const [dropDownActive, setDropDownActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [resMsg, setResMsg] = useState("");

    const [slotObj, setSlotObj] = useState({});
    const [timeSlotsArray, setTimeSlotsArray] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setPlanId(location.pathname.split("/")[3]);
        setSlotId(location.pathname.split("/")[4]);
    }, [location]);

    useEffect(() => {
        const findSlot = async () => {
            try {
                if (slotId) {
                    setIsLoading(true);
                    const res = await axios.get(getSingleSlotApi(slotId), { withCredentials: true });
                    if (res.status === 200) {
                        setSlotObj(res?.data);
                        setTimeSlotsArray(res?.data?.timeslots);
                        setIsLoading(false);
                    }
                }
            } catch (e) {
                setIsLoading(false);
                console.log(e);
            }
        }
        findSlot();
    }, [slotId]);

    const validateData = () => {
        if (!slotObj.startdate || !slotObj.enddate || !slotObj.slotstatus) {
            setIsError(true);
            setResMsg("Please fill out all the Fields ");
            return false;
        } else if (slotObj.startdate > slotObj.enddate) {
            console.log("Start lower")
            setIsError(true);
            setResMsg("Please Enter Valid Dates");
            return false;
        } else if (timeSlotsArray.length > 0) {
            for (let i = 0; i < timeSlotsArray.length; i++) {
                if (!timeSlotsArray[i].starttime || !timeSlotsArray[i].endtime || !timeSlotsArray[i].zoomlink || !timeSlotsArray[i].trainername) {
                    setIsError(true);
                    setResMsg("Please fill out all the available time slots");
                    return false;
                } else if (timeSlotsArray[i].starttime > timeSlotsArray[i].endtime) {
                    setIsError(true);
                    setResMsg("Start time must be lower than end time in all time slots");
                    return false;
                }
            }
        }
        setIsError(false);
        setResMsg("");
        return true;
    }

    const updateSlot = async (e) => {
        e.preventDefault();
        if (validateData()) {
            try {
                setIsLoading(true);
                const res = await axios.post(updateSlotApi(slotId), { ...slotObj, timeslots: timeSlotsArray }, { withCredentials: true });
                if (res.status === 200) {
                    setIsLoading(false);
                    setIsError(false);
                    navigate(`/admin/cohort/${planId}`)
                }
            } catch (e) {
                setIsLoading(false);
                setIsError(true);
                if (e.response.status === 400) {
                    setResMsg(e.response.data);
                } else if (e.response.status === 500) {
                    setResMsg(e.response.data);
                } else {
                    setResMsg("Sorry, Something went wrong - Please try after some time.");
                }
                console.log(e);
            }
        }
    }

    const handleDeleteTimeSlot = (e, id) => {
        e.preventDefault();
        setTimeSlotsArray(current =>
            current.filter((slot) => {
                return slot.slotid !== id;
            }));
    }


    const handleAddTimeSlot = (e) => {
        e.preventDefault();
        setTimeSlotsArray([...timeSlotsArray, {
            slotid: uuid(),
            starttime: "",
            endtime: "",
            zoomlink: "",
            trainername: ""
        }])
    }

    return (
        <div>
            <AdminTop adminDetails={adminDetails} />
            <div className="adminMainContent">
                <AdminSide setAdminLogged={setAdminLogged} />
                {
                    isLoading ?
                        <Loader adminLoader={true} /> :
                        <div className="adminUpdateSlot right">
                            <h2 className="title">
                                Update Slot
                            </h2>
                            <form>
                                <div className="fields">
                                    <div className="formField">
                                        <label className="fieldTitle" htmlFor="startdate">
                                            Start Date
                                        </label>
                                        <input onChange={(e) => setSlotObj({ ...slotObj, startdate: e.target.value })} id="startdate" type="date" value={slotObj.startdate} />
                                    </div>
                                    <div className="formField">
                                        <label className="fieldTitle" htmlFor="enddate">
                                            End Date
                                        </label>
                                        <input onChange={(e) => setSlotObj({ ...slotObj, enddate: e.target.value })} id="enddate" value={slotObj.enddate} type="date" />
                                    </div>
                                </div>
                                <div className="formField">
                                    <label className="fieldTitle" htmlFor="programName">
                                        Slot Status
                                    </label>
                                    <div className="dropDown">
                                        <div onClick={() => setDropDownActive(!dropDownActive)} className="dropDown-Btn">
                                            {slotObj?.slotstatus}
                                            <ion-icon name="caret-down-outline"></ion-icon>
                                        </div>
                                        {
                                            dropDownActive &&
                                            <div className="dropDown-content">
                                                <div onClick={(e) => {
                                                    setSlotObj({ ...slotObj, slotstatus: "Closed" });
                                                    setDropDownActive(false);
                                                }} className="dropDown-item">
                                                    Closed
                                                </div>
                                                <div onClick={(e) => {
                                                    setSlotObj({ ...slotObj, slotstatus: "Available" });
                                                    setDropDownActive(false);
                                                }} className="dropDown-item">
                                                    Available
                                                </div>
                                                <div onClick={(e) => {
                                                    setSlotObj({ ...slotObj, slotstatus: "Notify Me" });
                                                    setDropDownActive(false);
                                                }} className="dropDown-item">
                                                    Notify Me
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <label className="fieldTitle">
                                    Time Slots
                                </label>
                                {
                                    timeSlotsArray?.length > 0 &&
                                    <div className="timeSlots">
                                        {
                                            timeSlotsArray?.map((timeSlot, index) => (
                                                <div key={index} className="singleTimeSlot">
                                                    <div className="formField">
                                                        <label className="fieldTitle">
                                                            Start Time
                                                        </label>
                                                        <input onChange={(e) => {
                                                            let tempSlots = [];
                                                            for (let i = 0; i < timeSlotsArray.length; i++) {
                                                                if (i === index) {
                                                                    tempSlots.push({ ...timeSlotsArray[i], starttime: e.target.value })
                                                                } else {
                                                                    tempSlots.push(timeSlotsArray[i]);
                                                                }
                                                            }
                                                            setTimeSlotsArray(tempSlots);
                                                        }} type="time" value={timeSlot.starttime} />
                                                    </div>
                                                    <div className="formField">
                                                        <label className="fieldTitle">
                                                            End Time
                                                        </label>
                                                        <input onChange={(e) => {
                                                            let tempSlots = [];
                                                            for (let i = 0; i < timeSlotsArray.length; i++) {
                                                                if (i === index) {
                                                                    tempSlots.push({ ...timeSlotsArray[i], endtime: e.target.value })
                                                                } else {
                                                                    tempSlots.push(timeSlotsArray[i]);
                                                                }
                                                            }
                                                            setTimeSlotsArray(tempSlots);
                                                        }} type="time" value={timeSlot.endtime} />
                                                    </div>
                                                    <div className="formField">
                                                        <label className="fieldTitle">
                                                            Zoom Link
                                                        </label>
                                                        <input onChange={(e) => {
                                                            let tempSlots = [];
                                                            for (let i = 0; i < timeSlotsArray.length; i++) {
                                                                if (i === index) {
                                                                    tempSlots.push({ ...timeSlotsArray[i], zoomlink: e.target.value })
                                                                } else {
                                                                    tempSlots.push(timeSlotsArray[i]);
                                                                }
                                                            }
                                                            setTimeSlotsArray(tempSlots);
                                                        }} type="text" className="zoomLink" placeholder="Enter your zoom Link" value={timeSlot.zoomlink} />
                                                    </div>
                                                    <div className="formField">
                                                        <label className="fieldTitle">
                                                            Trainer Name
                                                        </label>
                                                        <input onChange={(e) => {
                                                            let tempSlots = [];
                                                            for (let i = 0; i < timeSlotsArray.length; i++) {
                                                                if (i === index) {
                                                                    tempSlots.push({ ...timeSlotsArray[i], trainername: e.target.value })
                                                                } else {
                                                                    tempSlots.push(timeSlotsArray[i]);
                                                                }
                                                            }
                                                            setTimeSlotsArray(tempSlots);
                                                        }} value={timeSlot.trainername} type="text" className="trainerName" placeholder="Enter Trainer Name" />
                                                    </div>
                                                    <div className="deleteField formField">
                                                        <button className="delSlot" onClick={(e) => handleDeleteTimeSlot(e, timeSlot.slotid)}>
                                                            <ion-icon name="trash-outline"></ion-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                }
                                <button className="addSlotBtn" onClick={handleAddTimeSlot}>
                                    <ion-icon name="add-outline"></ion-icon>
                                </button>
                                <p className={isError ? "resMsg error" : "resMsg"}>
                                    {resMsg}
                                </p>
                                <button onClick={updateSlot}>
                                    Update
                                </button>
                            </form>
                        </div>
                }
            </div>
        </div>
    )
}

export default AdminUpdateSlot
