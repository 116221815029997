import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import { createSlotApi } from '../Config/Api';
import AdminSide from './AdminSide';
import AdminTop from './AdminTop';
import Loader from './Loader';

const AdminCreateSlot = ({ adminDetails, setAdminLogged }) => {
    const [planId, setPlanId] = useState("");
    const [curSlotData, setCurSlotData] = useState({ startdate: "", enddate: "", slotstatus: "Available", timeslots: [] });
    const [dropDownActive, setDropDownActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [resMsg, setResMsg] = useState("");
    const navigate = useNavigate();
    const location = useLocation();

    // const [slotsObj, setSlotsObj] = useState({ slotStatus: "Available" });
    const initialTimeSlots = [
        {
            slotid: uuid(),
            starttime: "",
            endtime: "",
            zoomlink: "",
            trainername: ""
        }
    ]

    const [timeSlotsArray, setTimeSlotsArray] = useState(initialTimeSlots);



    useEffect(() => {
        setPlanId(location.pathname.split("/")[3]);
    }, [location]);

    const validateData = () => {
        if (!curSlotData.startdate || !curSlotData.enddate || !curSlotData.slotstatus) {
            setIsError(true);
            setResMsg("Please fill out all the Fields ");
            return false;
        } else if (curSlotData.startdate > curSlotData.enddate) {
            setIsError(true);
            setResMsg("Please Enter Valid Dates");
            return false;
        } else if (timeSlotsArray.length > 0) {
            for (let i = 0; i < timeSlotsArray.length; i++) {
                if (!timeSlotsArray[i].starttime || !timeSlotsArray[i].endtime || !timeSlotsArray[i].zoomlink || !timeSlotsArray[i].trainername) {
                    setIsError(true);
                    setResMsg("Please fill out all the available time slots");
                    return false;
                } else if (timeSlotsArray[i].starttime > timeSlotsArray[i].endtime) {
                    setIsError(true);
                    setResMsg("Start time must be lower than end time in all time slots");
                    return false;
                }
            }
        }
        setIsError(false);
        setResMsg("");
        return true;
    }

    const createSlot = async (e) => {
        e.preventDefault();
        if (validateData()) {
            try {
                setIsLoading(true);
                const res = await axios.post(createSlotApi, { ...curSlotData, cohortid: planId }, { withCredentials: true })
                if (res.status === 201) {
                    setIsLoading(false);
                    navigate(`/admin/cohort/${planId}`)
                    console.log(res);
                }
            } catch (e) {
                setIsLoading(false);
                setIsError(true);
                console.log(e);
                if (e.response.status === 400) {
                    setResMsg(e.response.data);
                } else if (e.response.status === 500) {
                    setResMsg(e.response.data);
                } else {
                    setResMsg("Sorry, Something went wrong - Please try after some time.");
                }
            }
        }
    }

    const handleDeleteTimeSlot = (e, id) => {
        e.preventDefault();
        setTimeSlotsArray(current =>
            current.filter((slot) => {
                return slot.slotid !== id;
            }));
    }

    const handleAddTimeSlot = (e) => {
        e.preventDefault();
        setTimeSlotsArray([...timeSlotsArray, {
            slotid: uuid(),
            starttime: "",
            endtime: "",
            zoomlink: "",
            trainername: ""
        }])
    }

    return (
        <div>
            <AdminTop adminDetails={adminDetails} />
            <div className="adminMainContent">
                <AdminSide setAdminLogged={setAdminLogged} />
                {
                    isLoading ?
                        <Loader adminLoader={true} /> :
                        <div className="adminCreateSlot right">
                            <h2 className="title">
                                Create Slot
                            </h2>
                            <form>
                                <div className="fields">
                                    <div className="formField">
                                        <label className="fieldTitle" htmlFor="startdate">
                                            Start Date
                                        </label>
                                        <input value={curSlotData.startdate} onChange={(e) => setCurSlotData({ ...curSlotData, startdate: e.target.value })} id="startdate" type="date" />
                                    </div>
                                    <div className="formField">
                                        <label className="fieldTitle" htmlFor="enddate">
                                            End Date
                                        </label>
                                        <input value={curSlotData.enddate} onChange={(e) => setCurSlotData({ ...curSlotData, enddate: e.target.value })} id="enddate" type="date" />
                                    </div>
                                </div>
                                <div className="formField">
                                    <label className="fieldTitle" htmlFor="programName">
                                        Slot Status
                                    </label>
                                    <div className="dropDown">
                                        <div onClick={() => setDropDownActive(!dropDownActive)} className="dropDown-Btn">
                                            {curSlotData?.slotstatus}
                                            <ion-icon name="caret-down-outline"></ion-icon>
                                        </div>
                                        {
                                            dropDownActive &&
                                            <div className="dropDown-content">
                                                <div onClick={(e) => {
                                                    setCurSlotData({ ...curSlotData, slotstatus: "Closed" });
                                                    setDropDownActive(false);
                                                }} className="dropDown-item">
                                                    Closed
                                                </div>
                                                <div onClick={(e) => {
                                                    setCurSlotData({ ...curSlotData, slotstatus: "Available" });
                                                    setDropDownActive(false);
                                                }} className="dropDown-item">
                                                    Available
                                                </div>
                                                <div onClick={(e) => {
                                                    setCurSlotData({ ...curSlotData, slotstatus: "Notify Me" });
                                                    setDropDownActive(false);
                                                }} className="dropDown-item">
                                                    Notify Me
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <label className="fieldTitle">
                                    Time Slots
                                </label>
                                <div className="timeSlots">
                                    {
                                        timeSlotsArray?.map((timeSlot, timeSlotIndex) => (
                                            <div key={timeSlotIndex} className="singleTimeSlot">
                                                <div className="formField">
                                                    <label className="fieldTitle" >
                                                        Start Time
                                                    </label>
                                                    <input value={timeSlot.starttime} onChange={(e) => {
                                                        setTimeSlotsArray(current =>
                                                            current.map((obj, index) => {
                                                                if (obj.slotid === timeSlot.slotid) {
                                                                    return { ...obj, starttime: e.target.value }
                                                                }
                                                                return obj;
                                                            })
                                                        )
                                                        setCurSlotData({ ...curSlotData, timeslots: timeSlotsArray });
                                                    }} type="time" />
                                                </div>
                                                <div className="formField">
                                                    <label className="fieldTitle" >
                                                        End Time
                                                    </label>
                                                    <input value={timeSlot.endtime} onChange={(e) => {
                                                        setTimeSlotsArray(current =>
                                                            current.map((obj, index) => {
                                                                if (obj.slotid === timeSlot.slotid) {
                                                                    return { ...obj, endtime: e.target.value }
                                                                }
                                                                return obj;
                                                            })
                                                        )
                                                        setCurSlotData({ ...curSlotData, timeslots: timeSlotsArray });
                                                    }} type="time" />
                                                </div>
                                                <div className="formField">
                                                    <label className="fieldTitle" >
                                                        Zoom Link
                                                    </label>
                                                    <input value={timeSlot.zoomlink} onChange={(e) => {
                                                        setTimeSlotsArray(current =>
                                                            current.map((obj, index) => {
                                                                if (obj.slotid === timeSlot.slotid) {
                                                                    return { ...obj, zoomlink: e.target.value }
                                                                }
                                                                return obj;
                                                            })
                                                        )
                                                        setCurSlotData({ ...curSlotData, timeslots: timeSlotsArray });
                                                    }} type="text" className="zoomLink" placeholder="Enter your zoom Link" />
                                                </div>
                                                <div className="formField">
                                                    <label className="fieldTitle" >
                                                        Trainer Name
                                                    </label>
                                                    <input value={timeSlot.trainername} onChange={(e) => {
                                                        setTimeSlotsArray(current =>
                                                            current.map((obj, index) => {
                                                                if (obj.slotid === timeSlot.slotid) {
                                                                    return { ...obj, trainername: e.target.value }
                                                                }
                                                                return obj;
                                                            })
                                                        )
                                                        setCurSlotData({ ...curSlotData, timeslots: timeSlotsArray });
                                                    }} type="text" className="trainerName" placeholder="Enter Trainer Name" />
                                                </div>
                                                <div className="deleteField formField">
                                                    <button className="delSlot" onClick={(e) => handleDeleteTimeSlot(e, timeSlot.slotid)}>
                                                        <ion-icon name="trash-outline"></ion-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <button className="addSlotBtn" onClick={handleAddTimeSlot}>
                                    <ion-icon name="add-outline"></ion-icon>
                                </button>
                                <p className={isError ? "resMsg error" : "resMsg"}>
                                    {resMsg}
                                </p>
                                <button onClick={createSlot}>
                                    Create
                                </button>
                            </form>
                        </div>
                }
            </div>
        </div>
    )
}

export default AdminCreateSlot