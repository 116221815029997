import axios from 'axios';
import React, { useContext, useState } from 'react'
import { planContext } from '../App';
import { checkoutApi, getFinalPriceApi, purchaseCohortZeroApi } from '../Config/Api';
import { getDate } from '../Data';
import { useNavigate } from 'react-router-dom';

const UserDetail = () => {
    const [userDetails, setUserDetails] = useState({ name: "", email: "", phone: "", promocode: "" });

    const { userSelectedPlan, userSelectedDates, userSelectedTimes } = useContext(planContext);
    const [finalPrice, setFinalPrice] = useState(userSelectedPlan?.totalprice);
    const [finalPromocode, setfinalPromocode] = useState("");

    const [isError, setIsError] = useState(false);
    const [resMsg, setResMsg] = useState("");
    const [isReqProcessing, setIsReqProcessing] = useState(false);
    const [codeType, setCodeType] = useState("");

    const navigate = useNavigate();

    const verifyCode = async (e) => {
        e.preventDefault();
        if (validateData()) {
            try {
                setIsReqProcessing(true);
                const res = await axios.post(getFinalPriceApi, {
                    promocode: userDetails.promocode.toLowerCase(),
                    email: userDetails.email.toLowerCase(),
                    price: userSelectedPlan.totalprice
                })

                if (res.status === 200) {
                    setIsReqProcessing(false);
                    setIsError(false);
                    setResMsg(res.data.message);
                    setFinalPrice(res.data.finalprice);
                    setfinalPromocode(userDetails.promocode.toLowerCase());
                    setCodeType(res.data.promocodetype);
                }
            } catch (err) {
                setIsReqProcessing(false);
                setIsError(true);
                setfinalPromocode("");
                setCodeType("");
                console.log(err);
                if (err.response.status === 400) {
                    setResMsg(err.response.data.message);
                } else if (err.response.status === 500) {
                    setResMsg(err.response.data.message);
                } else {
                    setResMsg("Sorry, Something went wrong - Please try after some time.");
                }
            }
        }
    }

    const validateData = () => {
        if (!userDetails.name || !userDetails.email || !userDetails.phone) {
            setIsError(true);
            setResMsg("Please fill out all the fields ");
            return false;
        } else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userDetails.email))) {
            setIsError(true);
            setResMsg("Please enter valid email address");
            return false;
        } else if (userDetails.name.length < 3) {
            setIsError(true);
            setResMsg("Please enter valid username");
            return false;
        }
        // else if (!(/^\d{10}$/.test(userDetails.phone))) {
        //     setIsError(true);
        //     setResMsg("Please Enter Valid Mobile Number");
        //     return false;
        // }
        setIsError(false);
        setResMsg("");
        return true;
    }

    // const handleToken = async (token, addresses) => {
    //     if (validateData()) {
    //         try {
    //             setIsReqProcessing(true);
    //             const res = await axios.post('/checkout/payment', {
    //                 token, userSelectedPlan, userSelectedDates, userSelectedTimes, finalPrice
    //             })

    //             console.log(res);
    //             if (res.status === 200) {
    //                 setIsReqProcessing(false);
    //                 setIsError(false);
    //                 setResMsg("Success")
    //                 console.log("Success");
    //             } else {
    //                 setIsReqProcessing(false);
    //                 setIsError(true);
    //                 setResMsg("Some Failure");
    //                 console.log("Some fAil");
    //             }
    //         }
    //         catch (e) {
    //             setIsError(true);
    //             setIsReqProcessing(false);
    //             setResMsg("Sorry, Something went wrong - Please try after some time.")
    //             console.log(e);
    //             console.log("fAilure");
    //         }
    //     }
    // }

    const handleCheckout = async () => {
        if (validateData()) {
            if (finalPrice > 0) {
                try {
                    setIsReqProcessing(true);
                    const res = await axios.post(checkoutApi, {
                        userSelectedPlan, userSelectedDates, userSelectedTimes, finalPrice,
                        username: userDetails.name,
                        email: userDetails.email.toLowerCase(),
                        discount: userSelectedPlan.totalprice - finalPrice,
                        promocode: finalPromocode.toLowerCase(),
                        promocodetype: codeType
                    })
                    if (res.data.url) {
                        // setIsReqProcessing(false);
                        window.location.href = res.data.url;
                    }
                } catch (e) {
                    // console.log(e)
                    setIsReqProcessing(false);
                    setIsError(true);
                    setResMsg("Sorry, Something went wrong - Please try after some time.");

                }
            } else if (finalPrice === 0) {
                try {
                    setIsReqProcessing(true);
                    const res = await axios.post(purchaseCohortZeroApi, {
                        userSelectedPlan, userSelectedDates, userSelectedTimes, finalPrice,
                        username: userDetails.name,
                        email: userDetails.email.toLowerCase(),
                        discount: userSelectedPlan.totalprice - finalPrice,
                        promocode: userDetails.promocode.toLowerCase(),
                        promocodetype: codeType
                    })
                    if (res.status === 200) {
                        setIsReqProcessing(false);
                        setIsError(false);
                        // console.log(res);
                        navigate(res.data);
                        // alert("Purchase Successs");
                    }
                } catch (err) {
                    setIsReqProcessing(false);
                    setIsError(true);
                    console.log(err);
                    if (err.response.status === 400) {
                        setResMsg(err.response.data);
                    } else if (err.response.status === 500) {
                        setResMsg(err.response.data);
                    } else {
                        setResMsg("Sorry, Something went wrong - Please try after some time.");
                    }
                }
            }
        }
    }

    return (
        <div className="userDetail">
            <div className="container">
                <div className="userDetailLeft">
                    <h2 className="userDetailTitle">
                        Complete registration for cohort
                    </h2>
                    <form action="">
                        <label htmlFor="name">Name</label>
                        <div className="formField">
                            <ion-icon name="person-outline"></ion-icon>
                            <input name="name" value={userDetails.name} onChange={(e) => {
                                setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
                            }} type="text" autoComplete="off" id="name" />
                        </div>
                        <label htmlFor="email">Email</label>
                        <div className="formField">
                            <ion-icon name="mail-outline"></ion-icon>
                            <input name="email" autoComplete="off" value={userDetails.email} onChange={(e) => {
                                setUserDetails({ ...userDetails, [e.target.name]: e.target.value.toLowerCase() });
                            }} type="email" id="email" />
                        </div>
                        <label htmlFor="phone">Phone Number</label>
                        <div className="formField">
                            <ion-icon name="call-outline"></ion-icon>
                            <input name="phone" autoComplete="off" value={userDetails.phone} onChange={(e) => {
                                setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
                            }} type="text" id="phone" />
                        </div>
                        <label htmlFor="promocode">
                            Promo Code / Referral Code
                        </label>
                        <div className="promoField formField">
                            <ion-icon name="ticket-outline"></ion-icon>
                            <input autoComplete="off" name="promocode" value={userDetails.promocode} placeholder="E.g FF2022" onChange={(e) => {
                                setUserDetails({ ...userDetails, [e.target.name]: e.target.value.toUpperCase() });
                            }} type="text" id="promocode" />
                            <button disabled={isReqProcessing} onClick={verifyCode}>Verify</button>
                        </div>
                        {
                            <p className={isError ? "resMsg error" : "resMsg"}>
                                {resMsg}
                            </p>
                        }
                    </form>
                    {/* <StripeCheckout
                        className="stripeBtn"
                        stripeKey="pk_test_51K0Kd5SAOZlwSfxxY3rD5wZGwfhvt1sKCa8xHUEuWABNtAfAnfACV6NDc7JQchGch7CNFkemVJuGDBRFpkDymXSa00RO8auxPu"
                        token={handleToken}
                        amount={finalPrice * 100}
                        name={userSelectedPlan?.title}
                        billingAddress
                        shippingAddress
                    >
                        <button disabled={isError} className="payBtn">
                            Book A Class
                        </button>
                    </StripeCheckout> */}
                    <button disabled={isReqProcessing} onClick={() => handleCheckout()} className="payBtn">
                        Book A Class
                    </button>
                </div>
                <div className="userDetailRight">
                    <div className="userDetailCard">
                        <h3 className="cardTitle">
                            Client Details
                        </h3>
                        <div className="detailBox">
                            <div className="boxRight">
                                <p className="username">
                                    {userDetails.name || "Username"}
                                </p>
                                <p className="useremail">
                                    {userDetails.email || "Email"}
                                </p>
                                <p>
                                    {userDetails.phone || "Phone"}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="planDetailCard">
                        <h3 className="cardTitle">
                            Cohort Details
                        </h3>
                        <div className="detailBox">
                            <p className="planTitle">
                                {userSelectedPlan?.title}
                            </p>
                            <p className="timePeriod">
                                {userSelectedPlan?.numberofweeks} Weeks
                            </p>
                            <div className="trainerDetail">
                                {/* <div className="trainerDetailLeft">
                                </div> */}
                                <div className="trainerDetailRight">
                                    <p>
                                        Instructor
                                    </p>
                                    <p>
                                        {userSelectedTimes?.trainername}
                                    </p>
                                </div>
                            </div>
                            <p className="dates">
                                Dates
                            </p>
                            <p className="dateValue">
                                {`${getDate(userSelectedDates?.startdate)} - ${getDate(userSelectedDates?.enddate)}`}
                            </p>
                            <p className="timeSlot">
                                Time Slot
                            </p>
                            <p className="timeValue">
                                {userSelectedTimes?.starttime} - {userSelectedTimes?.endtime}
                            </p>
                        </div>
                        <div className="paymentDetailBox">
                            <div className="boxLeft">
                                <p>
                                    Payment
                                </p>
                            </div>
                            <div className="boxRight">
                                <p className="totalAmount">
                                    ${finalPrice}USD
                                </p>
                                <p className="sessionAmount">
                                    ${parseInt(finalPrice) / parseInt(userSelectedPlan?.numberofweeks * 5)}/Session
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default UserDetail