import React, { createContext, useEffect, useState } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import HomePage from './Pages/HomePage'
import AboutPage from './Pages/AboutPage'
import ServicesPage from './Pages/ServicesPage'
import ContactPage from './Pages/ContactPage'
import Referralpage from './Pages/Referralpage'
import SuggestPlan from './Pages/SuggestPlan'
import UserDetailPage from './Pages/UserDetailPage'
import ScrollToTop from './Components/ScrollToTop'
import AdminLogin from './Pages/AdminLogin'
import AdminSales from './Components/AdminSales'
import AdminHistory from './Components/AdminHistory'
import AdminSettings from './Components/AdminSettings'
import AdminBannerUpdate from './Components/AdminBannerUpdate'
import AdminSingleCohort from './Components/AdminSingleCohort'
import AdminUpdateSlot from './Components/AdminUpdateSlot'
import AdminCreateSlot from './Components/AdminCreateSlot'
import AdminCreateCohort from './Components/AdminCreateCohort'
import ErrorPage from './Components/ErrorPage'
import { checkAdminApi, getAllPlanApi } from './Config/Api'
import axios from 'axios'
import AdminTransactions from './Components/AdminTransactions'
import AdminCustomers from './Components/AdminCustomers'
import CheckoutSuccess from './Components/CheckoutSuccess'
import AdminReset from './Pages/AdminReset'
import AdminOurDetails from './Components/AdminOurDetails'

export const quizContext = createContext();
export const planContext = createContext();

const App = () => {
  // const cookies = new Cookies();

  const [quizCompleted, setQuizCompleted] = useState(false);
  const [quizAnswers, setQuizAnswers] = useState({});
  const [isNewUser, setIsNewUser] = useState(false);
  const [prevPlanIndex, setPrevPlanIndex] = useState(20);

  const [userSelectedPlan, setUserSelectedPlan] = useState(null);
  const [userSelectedDates, setUserSelectedDates] = useState(null);
  const [userSelectedTimes, setUserSelectedTimes] = useState(null);

  const [allCohorts, setAllCohorts] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("allcohorts");
    const initialValue = JSON.parse(saved);
    return initialValue || [];
  });
  // const [allSlots, setAllSlots] = useState([]);

  const [adminLogged, setAdminLogged] = useState(false);
  const [adminDetails, setAdminDetails] = useState({});

  // console.log(allSlots);

  useEffect(() => {
    const checkAdmin = async () => {
      try {
        const checkAdmin = await axios.get(checkAdminApi, { withCredentials: true });
        if (checkAdmin.status === 200) { 
         setAdminLogged(true);
          setAdminDetails(checkAdmin.data.admin);
          // console.log(cookies.get('forwardfitness'))
        }
      } catch (e) {
        setAdminDetails({});
	setAdminLogged(false);
      }
    }
    const findPlans = async () => {
      try {
        const getAllCohort = await axios.get(getAllPlanApi);
        if (getAllCohort.data.length > 0) {
          function compare(a, b) {
            if (a.cohortnumber < b.cohortnumber) {
              return -1;
            }
            if (a.cohortnumber > b.cohortnumber) {
              return 1;
            }
            return 0;
          }
          setAllCohorts(getAllCohort.data.sort(compare));
        }
      } catch (e) {
        console.log(e);
      }
    }
    checkAdmin();
    findPlans();
  }, []);

  useEffect(() => {
    localStorage.setItem('allcohorts', JSON.stringify(allCohorts));
  }, [allCohorts]);


  return (
    <quizContext.Provider value={{ quizCompleted, setQuizCompleted, quizAnswers, setQuizAnswers, isNewUser, setIsNewUser, setPrevPlanIndex, prevPlanIndex }}>
      <planContext.Provider value={{ allCohorts, setAllCohorts, userSelectedPlan, setUserSelectedPlan, userSelectedDates, setUserSelectedDates, userSelectedTimes, setUserSelectedTimes }}>
        <ScrollToTop>
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/about" element={<AboutPage />} />
            <Route exact path="/services" element={<ServicesPage />} />
            <Route exact path="/contact" element={<ContactPage />} />
            <Route exact path="/referral" element={<Referralpage />} />
            <Route exact path="/services/suggestplans" element={quizCompleted ? <SuggestPlan /> : <Navigate to="/services" />} />
            <Route exact path="/services/userdetails" element={quizCompleted ? <UserDetailPage /> : <Navigate to="/services" />} />
            <Route exact path="/services/checkout-success/:planid/:dateid/:timeid/:price" element={<CheckoutSuccess />} />


            <Route exact path="/admin" element={adminLogged ? <Navigate to="/admin/sales/banner" /> : < AdminLogin setAdminDetails={setAdminDetails} setAdminLogged={setAdminLogged} />} />
            <Route exact path="/admin/reset_password" element={adminLogged ? <Navigate to="/admin/sales/banner" /> : < AdminReset />} />
            <Route exact path="/admin/sales" element={adminLogged ? <Navigate to="/admin/sales/banner" /> : <Navigate to="/admin" />} />
            <Route exact path="/admin/sales/banner" element={adminLogged ? <AdminSales setAdminLogged={setAdminLogged} adminDetails={adminDetails} /> : <Navigate to="/admin" />} />
            <Route exact path="/admin/sales/history" element={adminLogged ? <AdminHistory setAdminLogged={setAdminLogged} adminDetails={adminDetails} /> : <Navigate to="/admin" />} />
            <Route exact path="/admin/sales/history/:id" element={adminLogged ? <AdminBannerUpdate setAdminLogged={setAdminLogged} adminDetails={adminDetails} /> : <Navigate to="/admin" />} />
            <Route exact path="/admin/cohort" element={adminLogged ? <Navigate to="/admin/cohort/createcohort" /> : <Navigate to="/admin" />} />
            <Route exact path="/admin/cohort/createcohort" element={adminLogged ? <AdminCreateCohort setAdminLogged={setAdminLogged} adminDetails={adminDetails} /> : <Navigate to="/admin" />} />
            <Route exact path="/admin/cohort/:id" element={adminLogged ? <AdminSingleCohort setAdminLogged={setAdminLogged} adminDetails={adminDetails} /> : <Navigate to="/admin" />} />
            <Route exact path="/admin/cohort/:id/:slot" element={adminLogged ? <AdminUpdateSlot setAdminLogged={setAdminLogged} adminDetails={adminDetails} /> : <Navigate to="/admin" />} />
            <Route exact path="/admin/cohort/:id/createslot" element={adminLogged ? <AdminCreateSlot setAdminLogged={setAdminLogged} adminDetails={adminDetails} /> : <Navigate to="/admin" />} />
            <Route exact path="/admin/customers" element={adminLogged ? <AdminCustomers setAdminLogged={setAdminLogged} adminDetails={adminDetails} /> : <Navigate to="/admin" />} />
            <Route exact path="/admin/transactions" element={adminLogged ? <AdminTransactions setAdminLogged={setAdminLogged} adminDetails={adminDetails} /> : <Navigate to="/admin" />} />
            <Route exact path="/admin/at-a-glance" element={adminLogged ? <AdminOurDetails setAdminLogged={setAdminLogged} adminDetails={adminDetails} /> : <Navigate to="/admin" />} />
            <Route exact path="/admin/settings" element={adminLogged ? <AdminSettings setAdminLogged={setAdminLogged} adminDetails={adminDetails} setAdminDetails={setAdminDetails} /> : <Navigate to="/admin" />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </ScrollToTop>
      </planContext.Provider>
    </quizContext.Provider >
  )
}

export default App;
