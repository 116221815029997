import React from 'react'
import { Link } from 'react-router-dom';
import logo from "../Assets/logoBig.png";

const Footer = () => {
    return (
        <div className="footer">
            <div className="left">
                <Link to="/" className="logo">
                    <img loading="lazy" src={logo} alt="" />
                </Link>
                <p className="email">
                    Email: <span>amadou@foreverforward.org</span>
                </p>
            </div>
            <div className="center">
                <ul>
                    <li>
                        <Link to="/">
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="/about">
                            About Us
                        </Link>
                    </li>
                    <li>
                        <Link to="/services">
                            Services
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact">
                            Contact Us
                        </Link>
                    </li>
                    <li>
                        <Link to="/referral">
                            Referral
                        </Link>
                    </li>
                </ul>

                <div className="socialIcons">
                    <a href="https://www.facebook.com/ForwardFitness.biz" target="_blank" rel="noreferrer" className="icon">
                        <ion-icon name="logo-facebook"></ion-icon>
                    </a>
                    <a href="https://www.youtube.com/channel/UCKsNqndWV0Xtcocr8RSaMew" target="_blank" rel="noreferrer" className="icon">
                        <ion-icon name="logo-youtube"></ion-icon>
                    </a>
                    <a href="https://www.linkedin.com/company/forwardfitness/" target="_blank" rel="noreferrer" className="icon">
                        <ion-icon name="logo-linkedin"></ion-icon>
                    </a>
                    <a href="https://www.instagram.com/forwardfitness/?hl=en" target="_blank" rel="noreferrer" className="icon">
                        <ion-icon name="logo-instagram"></ion-icon>
                    </a>
                </div>
                <a href="https://www.unpause.xyz" target="_blank" rel="noreferrer" className="branding">
                    Designed by Unpause
                </a>
            </div>
        </div>
    )
}

export default Footer;