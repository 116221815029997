import React from 'react'
import ContactForm from '../Components/ContactForm';
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import ScrollToTopArrow from '../Components/ScrollToTopArrow';

const ContactPage = () => {
    return (
        <div className="contactPage" style={{ position: "relative" }}>
            <ScrollToTopArrow />
            <Navbar />
            <ContactForm />
            <Footer />
        </div>
    )
}

export default ContactPage