import React from 'react'
import Navbar from "../Components/Navbar";
import UserDetail from '../Components/UserDetail';
import Footer from '../Components/Footer';
import ScrollToTopArrow from '../Components/ScrollToTopArrow';

const UserDetailPage = () => {
    return (
        <div className="userDetailPage" style={{ position: "relative" }}>
            <ScrollToTopArrow />
            <Navbar color="black" />
            <UserDetail />
            <Footer />
        </div>
    )
}

export default UserDetailPage