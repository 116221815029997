export const mainDomain = "https://foreverforward.org/"

export const checkAdminApi = `https://foreverforward.org/api/auth/checkadmin`;

export const createBannerApi = `https://foreverforward.org/api/banner/create`;
export const getAllBannerApi = `https://foreverforward.org/api/banner/getallbanners`;
export const getSingleBannerApi = (id) => `https://foreverforward.org/api/banner/find/${id}`;
export const updateBannerApi = (id) => `https://foreverforward.org/api/banner/update/${id}`;
export const expireBannerApi = (id) => `https://foreverforward.org/api/banner/expire/${id}`;
export const fetchActiveBannerApi = `https://foreverforward.org/api/banner/findactive`;

export const getAllPlanApi = `https://foreverforward.org/api/cohort/findall`;
export const getAllSlotApi = `https://foreverforward.org/api/cohort/findallslots`;
export const createCohortApi = `https://foreverforward.org/api/cohort/create`;
export const getSinglePlanApi = (id) => `https://foreverforward.org/api/cohort/find/${id}`;
export const updateCohortApi = (id) => `https://foreverforward.org/api/cohort/update/${id}`;
export const deleteCohortApi = (id) => `https://foreverforward.org/api/cohort/delete/${id}`;


export const getSingleSlotApi = (id) => `https://foreverforward.org/api/cohort/slot/find/${id}`;
export const updateSlotApi = (id) => `https://foreverforward.org/api/cohort/slot/update/${id}`;
export const createSlotApi = `https://foreverforward.org/api/cohort/slot/create`;
export const deleteSlotApi = (id) => `https://foreverforward.org/api/cohort/slot/delete/${id}`;


export const loginApi = `https://foreverforward.org/api/auth/login`;
export const logoutApi = `https://foreverforward.org/api/auth/logout`;
export const forgotUserApi = `https://foreverforward.org/api/auth/forgotpassword`;
export const resetTokenVerify = `https://foreverforward.org/api/auth/reset_token_verify`;
export const resetPasswordApi = `https://foreverforward.org/api/auth/resetpassword`;
export const updateAdminDataApi = `https://foreverforward.org/api/auth/updatedata`;
export const updatepasswordApi = `https://foreverforward.org/api/auth/updatepassword`;

export const checkReferralApi = `https://foreverforward.org/api/referral/check`;
export const getReferralCodeApi = `https://foreverforward.org/api/referral/getcode`;
export const getFinalPriceApi = `https://foreverforward.org/api/referral/getfinalprice`;

export const submitContactFormApi = `https://foreverforward.org/api/contact/submitform`;

export const createNotificationAlertApi = `https://foreverforward.org/api/notify/create`;

export const newsLetterSubscribeApi = `https://foreverforward.org/api/subscribe/add`;


export const getAllCustomersApi = `https://foreverforward.org/api/customer/findall`;
export const deleteCustomerApi = (id) => `https://foreverforward.org/api/customer/${id}`;

export const getAllTransactionsApi = `https://foreverforward.org/api/transaction/findall`;

export const checkoutApi = `https://foreverforward.org/api/checkout/create-checkout-session`;
export const purchaseCohortZeroApi = `https://foreverforward.org/api/checkout/zeroprice`;

export const getPurchasedCohortApi = `https://foreverforward.org/api/checkout/purchasedcohort`;

export const getFFDetailApi = `https://foreverforward.org/api/ourdetails`;
export const updateFFDetailApi = (id) => `https://foreverforward.org/api/ourdetails/update/${id}`;

// "proxy": "http://localhost:5000",


// Production APIs

// export const checkAdminApi = "https://forwardfitness.herokuapp.com/api/auth/checkadmin";

// export const createBannerApi = "https://forwardfitness.herokuapp.com/api/banner/create";
// export const getAllBannerApi = "https://forwardfitness.herokuapp.com/api/banner/getallbanners";
// export const getSingleBannerApi = (id) => `https://forwardfitness.herokuapp.com/api/banner/find/${id}`;
// export const updateBannerApi = (id) => `https://forwardfitness.herokuapp.com/api/banner/update/${id}`;
// export const expireBannerApi = (id) => `https://forwardfitness.herokuapp.com/api/banner/expire/${id}`;
// export const fetchActiveBannerApi = "https://forwardfitness.herokuapp.com/api/banner/findactive";

// export const getAllPlanApi = "https://forwardfitness.herokuapp.com/api/cohort/findall";
// export const getAllSlotApi = "https://forwardfitness.herokuapp.com/api/cohort/findallslots";
// export const createCohortApi = "https://forwardfitness.herokuapp.com/api/cohort/create";
// export const getSinglePlanApi = (id) => `https://forwardfitness.herokuapp.com/api/cohort/find/${id}`;
// export const updateCohortApi = (id) => `https://forwardfitness.herokuapp.com/api/cohort/update/${id}`;
// export const deleteCohortApi = (id) => `https://forwardfitness.herokuapp.com/api/cohort/delete/${id}`;


// export const getSingleSlotApi = (id) => `https://forwardfitness.herokuapp.com/api/cohort/slot/find/${id}`;
// export const updateSlotApi = (id) => `https://forwardfitness.herokuapp.com/api/cohort/slot/update/${id}`;
// export const createSlotApi = `https://forwardfitness.herokuapp.com/api/cohort/slot/create`;
// export const deleteSlotApi = (id) => `https://forwardfitness.herokuapp.com/api/cohort/slot/delete/${id}`;


// export const loginApi = "https://forwardfitness.herokuapp.com/api/auth/login";
// export const logoutApi = "https://forwardfitness.herokuapp.com/api/auth/logout";
// export const forgotUserApi = "https://forwardfitness.herokuapp.com/api/auth/forgotpassword";
// export const resetTokenVerify = "https://forwardfitness.herokuapp.com/api/auth/reset_token_verify";
// export const resetPasswordApi = "https://forwardfitness.herokuapp.com/api/auth/resetpassword";
// export const updateAdminDataApi = "https://forwardfitness.herokuapp.com/api/auth/updatedata";
// export const updatepasswordApi = "https://forwardfitness.herokuapp.com/api/auth/updatepassword";

// export const checkReferralApi = "https://forwardfitness.herokuapp.com/api/referral/check";
// export const getReferralCodeApi = "https://forwardfitness.herokuapp.com/api/referral/getcode";
// export const getFinalPriceApi = "https://forwardfitness.herokuapp.com/api/referral/getfinalprice";

// export const submitContactFormApi = "https://forwardfitness.herokuapp.com/api/contact/submitform";

// export const createNotificationAlertApi = "https://forwardfitness.herokuapp.com/api/notify/create";

// export const newsLetterSubscribeApi = "https://forwardfitness.herokuapp.com/api/subscribe/add";


// export const getAllCustomersApi = "https://forwardfitness.herokuapp.com/api/customer/findall";
// export const deleteCustomerApi = (id) => `https://forwardfitness.herokuapp.com/api/customer/${id}`;

// export const getAllTransactionsApi = "https://forwardfitness.herokuapp.com/api/transaction/findall";

// export const checkoutApi = "https://forwardfitness.herokuapp.com/api/checkout/create-checkout-session";
// export const purchaseCohortZeroApi = "https://forwardfitness.herokuapp.com/api/checkout/zeroprice";

// export const getPurchasedCohortApi = `https://forwardfitness.herokuapp.com/api/checkout/purchasedcohort`;

// export const getFFDetailApi = 'https://forwardfitness.herokuapp.com/api/ourdetails/';

// export const updateFFDetailApi = (id) => `https://forwardfitness.herokuapp.com/api/ourdetails/update/${id}`;
