import React from 'react';
import watch from "../Assets/watch.gif";
import dumbel from "../Assets/dumbel.gif"
import bycep from "../Assets/bycep.gif"
import community from "../Assets/community.gif"
import coreValueBgFont from "../Assets/coreValueBgFont.png"

const CoreValues = () => {
    return (
        <div className="coreValues">
            <h2 className="title">
                Core Values
            </h2>
            <p className="desc">
                FF has always set out to be different from the thousands of the run-of-the-mill programs out there. We believe that busy people need efficient workouts that are highly accessible with trainers and a community that love and care about them. In this ecosystem, people can work hard, be consistent, and accountable to themselves, others, and their goals.
            </p>

            <div className="values">
                <div className="value">
                    <img loading="lazy" src={watch} alt="" />
                    <h3 className="valueTitle">
                        Time
                    </h3>
                    <p className="valueDesc">
                        Our 30-minute workouts are efficient and effective so you don’t have to spend hours in the gym.
                    </p>
                </div>
                <div className="value">
                    <img loading="lazy" src={dumbel} alt="" />
                    <h3 className="valueTitle">
                        Freedom
                    </h3>
                    <p className="valueDesc">
                        Forward Fitness’s virtual sessions go where you go. The world is your gym.
                    </p>
                </div>
                <div className="value">
                    <img loading="lazy" src={bycep} alt="" />
                    <h3 className="valueTitle">
                        Personalized
                    </h3>
                    <p className="valueDesc">
                        Professional coaches meet you where you are with customized adjustments.
                    </p>
                </div>
                <div className="value">
                    <img loading="lazy" src={community} alt="" />
                    <h3 className="valueTitle">
                        Community
                    </h3>
                    <p className="valueDesc">
                        At Forward Fitness, our group classes build a real sense of community and family through shared experiences.
                    </p>
                </div>
            </div>

            <a href="https://www.youtube.com/watch?v=fvCKvD016Ts" target="_blank" rel="noreferrer" className="btn learnmore">
                Learn More <ion-icon name="arrow-forward"></ion-icon>
            </a>

            <div className="coreValueBgFont">
                <img loading="lazy" src={coreValueBgFont} alt="" />
            </div>
        </div>
    )
}

export default CoreValues