import React, { useContext } from 'react';
import { planContext } from '../App';
import PlanService from './PlanService';


const AllPlansService = () => {
    const { allCohorts } = useContext(planContext);
    // const imgs = [plan1Img, plan2Img, plan3Img, plan4Img];


    return (
        <div className="allplans">
            {
                allCohorts.length > 0 &&
                allCohorts?.map((planDetail, index) => {
                    return (
                        <PlanService
                            key={index}
                            planIndex={index}
                            curPlan={planDetail}
                        />
                    )
                })
            }
        </div>
    )
}

export default AllPlansService;