import React, { useContext } from 'react'
import watermarkCohort1 from "../Assets/watermarkCohort1.png";
import watermarkCohort2 from "../Assets/watermarkCohort2.png";
import watermarkCohort3 from "../Assets/watermarkCohort3.png";
import watermarkCohort4 from "../Assets/watermarkCohort4.png";
import { quizContext } from "../App";
import { Link } from 'react-router-dom';
import plan1Img from "../Assets/plan1.png";
import plan2Img from "../Assets/plan2.png";
import plan3Img from "../Assets/plan3.png";
import plan4Img from "../Assets/plan4.png";

const PlanService = (props) => {
    const { planIndex, curPlan } = props;

    const { quizCompleted, isNewUser } = useContext(quizContext);

    const planImages = [plan1Img, plan2Img, plan3Img, plan4Img];
    const watermarkes = [watermarkCohort1, watermarkCohort2, watermarkCohort3, watermarkCohort4];

    return (
        <div className="planContainer">
            <div className={isNewUser ? "newUserPlan planDetails" : "planDetails"}>
                <div className="planLeft">
                    <h2 className="title">
                        {curPlan?.title}
                    </h2>
                    <div className="serviceBgFontMobile">
                        <img loading="lazy" src={watermarkes[planIndex % 4]} alt="" />
                    </div>
                    {
                        curPlan?.description.split('$').map((desc, index) => (
                            <p key={index} className="desc">
                                {desc}
                            </p>
                        ))
                    }
                    <Link to={!quizCompleted && "/services"}>
                        <button>
                            <div className="btnTop">
                                <p className="btnTitle">
                                    {curPlan?.title}
                                </p>
                                <p className="price">
                                    ${curPlan?.totalprice / (curPlan?.numberofweeks * 5)}/Session
                                </p>
                            </div>
                            <div className="btnBottom">
                                <p className="btnDesc">
                                    Cohort: {curPlan?.numberofweeks}-Weeks, {curPlan?.numberofweeks * 5} Sessions
                                </p>
                                <p className="subPrice">
                                    <span>${curPlan?.totalprice}</span>Total
                                </p>
                            </div>
                        </button>
                    </Link>
                </div>
                <div className="planRight">
                    <img loading="lazy" src={planImages[planIndex % 4]} alt="" />
                </div>
            </div>
            <div className="serviceBgFont">
                <img loading="lazy" src={watermarkes[planIndex % 4]} alt="" />
            </div>
        </div>
    )
}

export default PlanService;