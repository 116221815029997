import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import { planContext, quizContext } from '../App';
import { useNavigate } from "react-router-dom";

const Quiz = ({ quizStarted, setQuizStarted }) => {
    const [newUser, setNewUser] = useState();
    const [queNum, setQueNum] = useState(0);
    const [curChecks, setCurChecks] = useState([]);
    const [curSelects, setCurSelects] = useState([]);
    const [curSuggestion, setCurSuggestion] = useState("");
    const [curQuestion, setCurQuestion] = useState("");
    const [curAns, setCurAns] = useState("");
    const [curId, setCurId] = useState("");
    const [totalQues, setTotalQues] = useState(1);

    const [allCohortTitles, setAllCohortTitles] = useState([]);

    const { setQuizCompleted, setQuizAnswers, setIsNewUser, setPrevPlanIndex } = useContext(quizContext);
    const { allCohorts } = useContext(planContext);


    // console.log(allCohortTitles);

    const navigate = useNavigate();

    const [dropDownActive, setDropDownActive] = useState(false);

    const [answers, setAnswers] = useState({});

    const startQuiz = () => {
        if (newUser !== true && newUser !== false) {
            setCurQuestion("Have you worked out with us before?");
            setQueNum(1);
            setCurChecks(["Yes", "No"]);
            setCurId("que1");
            setQuizStarted(true);
        }
    }

    const oldUserQues = [
        {
            id: "que2",
            que: "Which cohort did you participate in last?",
            options: allCohortTitles,
            checks: []
        }
    ]

    const newUserQues = [
        {
            id: "que2",
            que: "Are you more interested in core or full body classes?",
            suggestion: "",
            options: [],
            checks: ["CORE", "FULL BODY"]
        },
        {
            id: "que3",
            que: "How often do you work out in a week?",
            suggestion: "",
            options: [],
            checks: ["0-1", "2-3", "4-5", "6+"]
        },
        {
            id: "que4",
            que: "Have you been a part of an organized activity?",
            suggestion: "(Ie. soccer team, dance team, track team, etc) ",
            options: [],
            checks: ["Yes", "No"]
        },
        {
            id: "que5",
            que: "Can you do a push-up on your feet?",
            suggestion: "",
            options: [],
            checks: ["Yes", "No"]
        },
        {
            id: "que6",
            que: "Have you ever done weight training?",
            suggestion: "",
            options: [],
            checks: ["Yes", "No"]
        },
    ]

    const nextQuestion = () => {
        if (!curAns)
            return;
        if (queNum === 1) {
            if (curAns === "Yes") {
                setNewUser(false);
                setTotalQues(oldUserQues.length + 1);
                setAnswers({});
            }
            else if (curAns === "No") {
                setNewUser(true);
                setTotalQues(newUserQues.length + 1);
                setAnswers({});
            }
            else
                return;
        }
        if ((queNum === 1 && curAns === "Yes") || newUser === false) {
            setAnswers({ [`que${queNum}`]: { "que": curQuestion, "ans": curAns }, ...answers });
            setCurAns("");
            if (answers[`que${queNum + 1}`]) {
                setCurAns(answers[`que${queNum + 1}`].ans);
            }
            setCurQuestion(oldUserQues[queNum - 1].que);
            setCurChecks(oldUserQues[queNum - 1].checks);
            setCurSelects(oldUserQues[queNum - 1].options);
            setCurId(oldUserQues[queNum - 1].id);
            setQueNum(queNum + 1);
        } else {
            setAnswers({ [`que${queNum}`]: { "que": curQuestion, "ans": curAns }, ...answers });
            setCurAns("");
            if (answers[`que${queNum + 1}`]) {
                setCurAns(answers[`que${queNum + 1}`].ans);
            }
            setCurQuestion(newUserQues[queNum - 1].que);
            setCurChecks(newUserQues[queNum - 1].checks);
            setCurSelects(newUserQues[queNum - 1].options);
            setCurSuggestion(newUserQues[queNum - 1].suggestion);
            setCurId(newUserQues[queNum - 1].id);
            setQueNum(queNum + 1);
        }
    }

    const prevQuestion = () => {
        if (queNum === 2) {
            setCurQuestion("Have you worked out with us before?");
            setQueNum(1);
            setNewUser();
            setCurAns(answers[`que${queNum - 1}`].ans);
            setCurChecks(["Yes", "No"]);
            setCurSelects([]);
            setAnswers({});
        } else if (newUser === false) {
            setCurAns(answers[`que${queNum - 1}`].ans);
            setCurQuestion(oldUserQues[queNum - 2].que);
            setCurChecks(oldUserQues[queNum - 2].checks);
            setCurSelects(oldUserQues[queNum - 2].options);
            setQueNum(queNum - 1);
        } else {
            setCurAns(answers[`que${queNum - 1}`].ans);
            setCurQuestion(newUserQues[queNum - 3].que);
            setCurChecks(newUserQues[queNum - 3].checks);
            setCurSelects(newUserQues[queNum - 3].options);
            setCurSuggestion(newUserQues[queNum - 3].suggestion);
            setQueNum(queNum - 1);
        }
    }

    const completeQuiz = () => {
        let preIndex;
        if (!newUser) {
            preIndex = allCohorts.filter((plan) => plan.title === answers.que2.ans)[0].cohortnumber;
            setPrevPlanIndex(preIndex);
        }
        setQuizCompleted(true);
        setQuizAnswers({ ...answers });
        setIsNewUser(newUser);
        navigate("/services/suggestplans");
    }

    const submitQuiz = () => {
        if (!curAns)
            return;
        setAnswers({ [`que${queNum}`]: { "que": curQuestion, "ans": curAns }, ...answers })
        setCurAns("");
    }

    useEffect(() => {
        if (Object.keys(answers).length === totalQues)
            completeQuiz();
    }, [answers]);

    useEffect(() => {
        setDropDownActive(false);
        setCurAns(curSelects[0]);
    }, [curSelects]);

    useEffect(() => {
        setAllCohortTitles(allCohorts?.map((cohort) => cohort.title))
    }, [allCohorts]);


    return (
        <div className="quiz" id="quizComponent">
            {
                (queNum === 0)
                &&
                <>
                    <h2 className="title">
                        Take The Quiz
                    </h2>
                    <h3 className="slogan">
                        Find out which program is right for you?
                    </h3>
                    <button onClick={startQuiz} className="btn learnmore">
                        Get Started <ion-icon name="arrow-forward"></ion-icon>
                    </button>
                </>
            }

            <div className={quizStarted ? "activeQueBox questionBox" : "questionBox"}>
                {
                    curQuestion &&
                    <>
                        <h2 className="question">
                            {queNum}. {curQuestion}
                        </h2>
                        <h3 className="suggestion">
                            {curSuggestion}
                        </h3>
                        <div className="checks">
                            {
                                curChecks &&
                                curChecks.map((curCheck, index) => {
                                    return (
                                        <div key={"radio" + index}>
                                            <input type="radio" checked={curAns === curCheck || answers[`que${queNum}`] === curCheck} onChange={(e) => setCurAns(e.target.value)} name={curId} id={curCheck} value={curCheck} />
                                            <label htmlFor={curCheck} className="box"></label>
                                            <label className='text' htmlFor={curCheck}>{curCheck}</label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="selects">
                            {
                                curSelects.length > 0 &&
                                (
                                    <div className="dropDown">
                                        <div onClick={() => setDropDownActive(!dropDownActive)} className="dropDown-Btn">
                                            {curAns}
                                            <ion-icon name="caret-down-outline"></ion-icon>
                                        </div>
                                        {
                                            dropDownActive &&
                                            <div className="dropDown-content">
                                                {
                                                    curSelects.map((curSelect, index) => {
                                                        return (
                                                            <div key={"select" + index} onClick={(e) => {
                                                                setCurAns(curSelect);
                                                                setDropDownActive(false);
                                                            }} className="dropDown-item">
                                                                {curSelect}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>
                                )
                            }
                        </div>


                        {
                            (queNum === totalQues && queNum !== 1)
                                ?
                                <button onClick={submitQuiz} className="btn">
                                    Submit
                                </button>
                                :
                                <button onClick={nextQuestion} className="btn">
                                    Next <ion-icon name="arrow-forward"></ion-icon>
                                </button>
                        }
                    </>
                }
            </div>

            {
                (queNum > 0) &&
                <div className="preNextBtns">
		    <button onClick={prevQuestion} disabled={queNum === 1} className="pre">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </button>
                    <button onClick={nextQuestion} disabled={queNum === totalQues || queNum === 1} className="next">
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                    </button>
                </div>
            }
        </div>
    )
}

export default Quiz
