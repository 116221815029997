import axios from 'axios';
import React, { useEffect, useState } from 'react'
import deleteBtn from "../Assets/deleteBtn.png";
import { deleteCustomerApi, getAllCustomersApi } from '../Config/Api';
import AdminSide from './AdminSide'
import AdminTop from './AdminTop'
import Loader from './Loader';
import { ExportJsonCsv } from 'react-export-json-csv';

const AdminCustomers = ({ adminDetails, setAdminLogged }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [customerData, setCustomerData] = useState([]);
    const [filteredCustomer, setFilteredCustomer] = useState([]);

    const [titleSelects, setTitleSelects] = useState([]);
    const [slotSelects, setSlotSelects] = useState([]);
    const [timeSelects, setTimeSelects] = useState([]);

    const [csvData, setCsvData] = useState([]);

    const headers = [
        {
            key: 'id',
            name: 'ID',
        },
        {
            key: 'username',
            name: 'Username',
        },
        {
            key: 'email',
            name: 'Email',
        },
        {
            key: 'cohortname',
            name: 'Cohort Name',
        },
        {
            key: 'dateslot',
            name: 'Cohort Date Slot',
        },
        {
            key: 'timeslot',
            name: 'Cohort Times',
        }
    ]

    useEffect(() => {
        const size = filteredCustomer.length;
        let tempData = [];

        for (let i = 0; i < size; i++) {
            tempData.push({ id: i, username: filteredCustomer[i].username, email: filteredCustomer[i].email, cohortname: filteredCustomer[i].plantitle, dateslot: filteredCustomer[i].plandate, timeslot: filteredCustomer[i].plantime })
        }
        setCsvData(tempData);
    }, [filteredCustomer]);

    const [dropDownActive, setDropDownActive] = useState("");
    const [filters, setFilters] = useState({ titleFilter: "All Plans", slotFilter: "All Slots", timeFilter: "All Time Slots" });

    useEffect(() => {
        const getCustomers = async () => {
            try {
                setIsLoading(true);
                const allCustomers = await axios.get(getAllCustomersApi, { withCredentials: true });
                if (allCustomers.status === 200) {
                    setIsLoading(false);
                    setCustomerData(allCustomers.data.reverse());
                }
            } catch (e) {
                setIsLoading(false);
                console.log(e);
            }
        }
        getCustomers();
    }, []);

    useEffect(() => {
        setFilteredCustomer(customerData);
        const planTitles = new Set(customerData.map((customer) => customer.plantitle));
        const planSlots = new Set(customerData.map((customer) => customer.plandate));
        const planTimes = new Set(customerData.map((customer) => customer.plantime));
        setTitleSelects(["All Plans", ...planTitles]);
        setSlotSelects(["All Slots", ...planSlots]);
        setTimeSelects(["All Time Slots", ...planTimes]);
        // if (titleSelects)
        //     // setTitleAns(titleSelects[0]);
        //     setFilters({ ...filters, titleFilter: titleSelects[0] });
        // if (slotSelects)
        //     // setSlotAns(slotSelects[0]);
        //     setFilters({ ...filters, slotFilter: slotSelects[0] });
        // if (timeSelects)
        //     setFilters({ ...filters, timeFilter: timeSelects[0] });
    }, [customerData]);

    const deleteCustomer = async (custid) => {
        if (window.confirm("Are you sure you want to remove this customer?")) {
            try {
                setIsLoading(true);
                const res = await axios.post(deleteCustomerApi(custid), { withCredentials: true })
                if (res.status === 200) {
                    setIsLoading(false);
                    window.location.reload();
                }
            } catch (e) {
                setIsLoading(false);
                console.log(e);
            }
        }
    }

    const handleFilter = (name, value) => {
        // setFilterAdded(true)
        setFilters({
            ...filters,
            [name]: value
        })
    }

    useEffect(() => {
        setFilteredCustomer(
            customerData.filter((customer) =>
                Object.entries(filters).every(([key, value]) => {
                    if (key === "titleFilter" && value !== "All Plans")
                        return customer.plantitle === value;
                    else if (key === "slotFilter" && value !== "All Slots")
                        return customer.plandate === value;
                    else if (key === "timeFilter" && value !== "All Time Slots")
                        return customer.plantime === value;
                    else
                        return true;
                })
            )
        )
    }, [filters])

    return (
        <div>
            <AdminTop adminDetails={adminDetails} />
            <div className="adminMainContent">
                <AdminSide setAdminLogged={setAdminLogged} />
                {
                    isLoading ?
                        <Loader adminLoader={true} /> :
                        <div className="adminHistory right">
                            <h2 className="title">
                                Customer Data
                            </h2>
                            <div className="filterContainer">
                                {
                                    customerData.length > 0 &&
                                    <div className="filter">
                                        <span className="filterText">
                                            Filter Customers :
                                        </span>
                                        <div className="dropDown">
                                            <div onClick={() => { (dropDownActive === "titleDropdown") ? setDropDownActive("") : setDropDownActive("titleDropdown") }} className="dropDown-Btn">
                                                {filters.titleFilter}
                                                <ion-icon name="caret-down-outline"></ion-icon>
                                            </div>
                                            {
                                                (dropDownActive === "titleDropdown") &&
                                                <div className="dropDown-content">
                                                    {
                                                        titleSelects.map((curSelect, index) => {
                                                            return (
                                                                <div key={"select" + index} onClick={() => {
                                                                    // setTitleAns(curSelect);
                                                                    handleFilter("titleFilter", curSelect);
                                                                    setDropDownActive("");
                                                                }} className="dropDown-item">
                                                                    {curSelect}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                        </div>
                                        <div className="dropDown">
                                            <div onClick={() => { (dropDownActive === "slotDropdown") ? setDropDownActive("") : setDropDownActive("slotDropdown") }} className="dropDown-Btn">
                                                {filters.slotFilter}
                                                <ion-icon name="caret-down-outline"></ion-icon>
                                            </div>
                                            {
                                                (dropDownActive === "slotDropdown") &&
                                                <div className="dropDown-content">
                                                    {
                                                        slotSelects.map((curSelect, index) => {
                                                            return (
                                                                <div key={"select" + index} onClick={() => {
                                                                    // setSlotAns(curSelect);
                                                                    handleFilter("slotFilter", curSelect);
                                                                    setDropDownActive("");
                                                                }} className="dropDown-item">
                                                                    {curSelect}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                        </div>
                                        <div className="dropDown">
                                            <div onClick={() => { (dropDownActive === "timeDropdown") ? setDropDownActive("") : setDropDownActive("timeDropdown") }} className="dropDown-Btn">
                                                {filters.timeFilter}
                                                <ion-icon name="caret-down-outline"></ion-icon>
                                            </div>
                                            {
                                                (dropDownActive === "timeDropdown") &&
                                                <div className="dropDown-content">
                                                    {
                                                        timeSelects.map((curSelect, index) => {
                                                            return (
                                                                <div key={"select" + index} onClick={() => {
                                                                    // setTimeAns(curSelect);
                                                                    handleFilter("timeFilter", curSelect);
                                                                    setDropDownActive("");
                                                                }} className="dropDown-item">
                                                                    {curSelect}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    filteredCustomer.length > 0 &&
                                    <ExportJsonCsv className="exportBtn" headers={headers} items={csvData}>Export</ExportJsonCsv>
                                }
                            </div>

                            <div className="tableDiv" style={{ overflow: "auto" }}>
                                {
                                    filteredCustomer.length > 0 ?
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Username</th>
                                                    <th>Email</th>
                                                    <th>Plan Title</th>
                                                    <th>Plan Slot</th>
                                                    <th>Plan Times</th>
                                                    {/* <th>Edit</th> */}
                                                    <th>Remove</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    filteredCustomer?.map((customer, index) => (
                                                        <tr key={index}>
                                                            <td className="bannerText">
                                                                {customer.username}
                                                            </td>
                                                            <td>
                                                                {customer.email.toLowerCase()}
                                                            </td>
                                                            <td>
                                                                {customer.plantitle}
                                                            </td>
                                                            <td>
                                                                {customer.plandate}
                                                            </td>
                                                            <td>
                                                                {customer.plantime}
                                                            </td>
                                                            {/* <td className="end">
                                                        <button onClick={() => navigate(`/admin/customers/${customer._id}`)}>
                                                            <img src={editBtn} alt="" />
                                                        </button>
                                                    </td> */}
                                                            <td className="end">
                                                                <button onClick={() => deleteCustomer(customer._id)}>
                                                                    <img src={deleteBtn} alt="" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        :
                                        <p className="noData">
                                            No Customer Data Available
                                        </p>
                                }
                            </div>
                        </div>
                }
            </div>
        </div >
    )
}

export default AdminCustomers
