import axios from 'axios'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getPurchasedCohortApi } from '../Config/Api'
import { getDate } from '../Data'
import Loader from './Loader'

import checkoutsuccessImg from "../Assets/checkoutSuccess.png";
import Navbar from './Navbar'


const CheckoutSuccess = () => {
    const [msg, setMsg] = useState("");
    const [purchasedData, setPurchasedData] = useState([]);
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [orderTotal, setOrderTotal] = useState("");

    //console.log(purchasedData);

    useEffect(() => {
        const getData = async () => {
            try {
                let ids = location.pathname.split("/");
                let planid = ids[3];
                let dateid = ids[4];
                let timeid = ids[5];
                let orderAmount = ids[6];
                setOrderTotal(orderAmount);
                setIsLoading(true);
                const res = await axios.post(getPurchasedCohortApi, {
                    planid,
                    dateid,
                    timeid
                });
                if (res.status === 200) {
                    setIsLoading(false);
                    setIsError(false);
                    setPurchasedData(res.data);
                }
            } catch (e) {
                setIsLoading(false);
                setIsError(true);
                // console.log(e);
            }
        }
        getData();
    }, []);

    function myFunction() {
        var copyText = document.getElementById("myInput");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
        setMsg("URL is copied to the clipboard ");
    }

    return (

        isLoading ?
            <Loader /> :
            <div className="checkoutSuccess">
                <Navbar />
                <div className="checkoutSuccessleft">
                    <h2 className="title">
                        Your payment has been successful and {purchasedData.trainername} is ready for you!
                    </h2>
                    <p className="subtitle">
                        Please keep an eye out for the following emails:
                    </p>
                    <ul>
                        <li>
                            DocuSign for the Order Form & our standard Customer Agreement
                        </li>
                        <li>
                            Calendar Invite for the classes (will be sent 24 hrs before the first class)
                        </li>
                    </ul>
                    <div className="detail">
                        <div className="detailLeft">
                            <div className="icon">
                                <ion-icon name="location-outline"></ion-icon>
                            </div>
                        </div>
                        <div className="detailRight">
                            <p className="key">
                                Here's the zoom link for the class:
                            </p>
                            <div className="urlBox">
                                <input readOnly className="left" type="text" value={purchasedData.zoomlink} id="myInput" />
                                <button className="right" onClick={myFunction}>
                                    <ion-icon name="copy"></ion-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <p className="successMsg">
                        {msg}
                    </p>
                    <div className="detail">
                        <div className="detailLeft">
                            <div className="icon">
                                <ion-icon name="ticket-outline"></ion-icon>
                            </div>
                        </div>
                        <div className="detailRight">
                            <p className="key">
                                Ticket
                            </p>
                            <p className="value">
                                Order total: ${orderTotal}
                            </p>
                        </div>
                    </div>
                    <div className="detail">
                        <div className="detailLeft">
                            <div className="icon">
                                <ion-icon name="time-outline"></ion-icon>
                            </div>
                        </div>
                        <div className="detailRight">
                            <p className="key">
                                {
                                    purchasedData.plantitle
                                }
                            </p>
                            <p className="key">
                                {getDate(purchasedData.startdate)} - {getDate(purchasedData.enddate)} from
                            </p>
                            <p className='key'>
                                {purchasedData.starttime} to {purchasedData.endtime} EST
                            </p>
                        </div>
                    </div>
                </div>
                <div className="checkoutSuccessright">
                    <img src={checkoutsuccessImg} alt="" />
                </div>
            </div>


    )
}

export default CheckoutSuccess
